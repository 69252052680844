export abstract class OavConstants {

    static readonly OUI: string = 'OUI';
    static readonly NON: string = 'NON';
    static readonly FORMULE: string = 'formules';
    static readonly DEVIS: string = 'devis';
    static readonly FORMULE_SANTE_URL: string = '/sante/formules';
    static readonly PROFIL_SANTE_URL: string = '/sante';
    static readonly BESOIN_SANTE_URL: string = '/sante/besoins';
    static readonly OAV_REDIRECTION: string = '/oav-product';
    static readonly MANDAT_SIGNATURE_SANTE_URL: string = '/sante/mandat-signature';
    static readonly PAIEMENT_SANTE_URL: string = '/sante/paiement';
    static readonly INFORMATION_SANTE_URL: string = '/sante/informations';
    static readonly PROFIL_ACDS_URL: string = '/acds';
    static readonly LOGIN_URL: string = '/login';
    static readonly SAUVEGARDE_DEVIS_SANTE_URL: string = '/sante/sauvegarde-devis';
    static readonly SAUVEGARDE_DEVIS_ACDS_URL: string = '/acds/sauvegarde-devis';
    static readonly DOCTOR: string = 'doctor';
    static readonly PHARMA: string = 'pharma';
    static readonly HOSPI: string = 'hospi';
    static readonly DENTAIRE: string = 'dent';
    static readonly OPTIQUE: string = 'optique';
    static readonly PLANE: string = 'plane';
    static readonly NEW_DEVIS: string = 'newDevis';
    static readonly YES: string = 'yes';
    static readonly VALID_PHONE_NUMBER_07: string = '07';
    static readonly VALID_PHONE_NUMBER_06: string = '06';
    static readonly NULL_STRING: string = '';
    static readonly IMAGE_JPEG: string = 'image/jpeg';
    static readonly APPLICATION_PDF: string = 'application/pdf';
    static readonly IMAGE_PNG: string = 'image/png';
    static readonly FILE_MAX_SIZE: number = 2000000;
    static readonly PRLVUNIQUE: string = 'PRLVUNIQUE';
    static readonly PRLVMENSUEL: string = 'PRLVMENSUEL';
    static readonly NB_MAX_ENFANT: number = 9;
    static readonly SANTE_AGE_MAX: number = 81;
    static readonly SANTE_AGE_MIN: number = 16;
    static readonly ACDS_AGE_MAX: number = 65;
    static readonly ACDS_AGE_MIN: number = 18;
    static readonly CLIENT_ID: string = 'spring-security-oauth2-read-write-client';
    static readonly CLIENT_SECRET: string = 'spring-security-oauth2-read-write-client-password1234';
    // tslint:disable-next-line: max-line-length
    static readonly ASSURANCE_AUTO_LINK: string = 'https://www.iassure.fr/';
    // tslint:disable-next-line: max-line-length
    static readonly ASSURANCE_HABITATION_LINK: string = 'https://www.iassure.fr/';
    // tslint:disable-next-line: max-line-length
    static readonly ASSURANCE_PROTECTION_JURIDIQUE_LINK: string = 'https://www.iassure.fr/';
    // tslint:disable-next-line: max-line-length
    static readonly ASSURANCE_ACCIDENT_VIE_LINK: string = 'https://www.iassure.fr/';
    // tslint:disable-next-line: max-line-length
    static readonly COMPLAIMENTAIRE_SANTE_LINK: string = 'https://www.iassure.fr/';
    static readonly FACEBOOK_LINK: string = 'https://www.facebook.com/iassurance';
    static readonly TWITTER_LINK: string = 'https://twitter.com/iassurance';
    static readonly YOUTUBE_LINK: string = 'https://www.youtube.com/iassurance ';
    static readonly BANQUE_POSTAL_LINK: string = 'https://www.labanquepostale.com/';
    static readonly DONNEES_PERSONNEL_LINK: string = 'https://www.iassure.fr/';
    static readonly MENTIONS_LEGALES_LINK: string = 'https://www.iassure.fr/';
    static readonly ESPACE_PROSPECT: string = '/espace-prospect';
    static readonly RENIT_PASS_RESUME: string = '/pass-changé';
    static readonly ESPACE_AGENT: string = '/espace-agent';
    static readonly LIST_DEVIS = '/espace-prospect/listDevis';
    static readonly RESUME_DEVIS = '/espace-prospect/resumeDevis';
    static readonly RESUME_CONTRAT = '/espace-prospect/resumeContrat';
    static readonly LIST_CONTRAT = '/espace-prospect/listContrats';
    static readonly PRODUIT_SANTE: string = 'SANTE';
    static readonly PRODUIT_ACDS: string = 'ACDS';
    static readonly PRODUIT_OAV: string = 'OAV';
    static readonly HOME_URL: string = '/';
    static readonly PROFIL_URL_LIST: string[] = [OavConstants.HOME_URL, OavConstants.PROFIL_SANTE_URL, OavConstants.PROFIL_ACDS_URL];
    static readonly PRODUIT_SANTE_ID: number = 1;
    static readonly PRODUIT_ACDS_ID: number = 2;
    static readonly PACOURS_AGENT: string = 'AGENT';
    static readonly PACOURS_PROSPECT: string = 'MIXTE';
    static readonly BESOIN_ACDS_URL: string = '/acds/besoins';
    static readonly FORMULE_ACDS_URL: string = '/acds/formules';
    static readonly INFORMATION_ACDS_URL: string = '/acds/informations';
    static readonly PRODUIT: string = 'produit';
    static readonly PARCOURS: string = 'parcours';
    static readonly RESUME_SANTE_URL: string = '/sante/resumeFormule';
    static readonly RESUME_ACDS_URL: string = '/acds/resumeFormule';
    static readonly MANDAT_SIGNATURE_ACDS_URL: string = '/acds/mandat-signature';
    static readonly PAIEMENT_ACDS_URL: string = '/acds/paiement';
    static readonly TYPE_SIGNATURE_AUTO: string = 'AUTO';
    static readonly TYPE_SIGNATURE_MANUAL: string = 'MANUAL';
    static readonly VALIDATION_ACDS_URL: string = '/acds/validation';
    static readonly VALIDATION_SANTE_URL: string = '/sante/validation';
    static readonly PASSWORD_ENCRYPTION_KEY: string = 'h$@1A9k@&l0\\AF8\/';
    static readonly FIRST_STEPS_URL_LIST: string[] = [OavConstants.HOME_URL, OavConstants.PROFIL_SANTE_URL, OavConstants.PROFIL_ACDS_URL,
       OavConstants.FORMULE_SANTE_URL, OavConstants.FORMULE_ACDS_URL, OavConstants.BESOIN_SANTE_URL, OavConstants.BESOIN_ACDS_URL];
   static readonly PRODUIT_CI = 'CI';
   static readonly IDLE_TOTAL_DURATION_ALLOWED_TIME_IN_SECONDS: number = 900;
   static readonly IDLE_DURATION_UNTIL_WARNING_IN_SECONDS: number = 60;
   static readonly IDLE_STATUS_USER_ACTIVE: number = 0;
   static readonly IDLE_STATUS_IDLE_WARNING: number = 1;
   static readonly IDLE_STATUS_USER_AWAY: number = 2;
   static readonly ECRAN_PROFIL_SANTE = '9899898987E4512121212P45658758S631141';
   static readonly ECRAN_BESOIN_SANTE = '5587555221E124124552B456221145S5566889';
   static readonly ECRAN_FORMULE_SANTE = '000025221554E00151F65521204S54551222';
   static readonly ECRAN_RESUME_FORMULE_SANTE = '12155222E5898656R87455545F656565666S99896545';
   static readonly ECRAN_INFORMATIONS_SANTE = '4555545522E52255I554555S666655';
   static readonly ECRAN_SAUVEGARDE_INFORMATIONS_SANTE = '4155151E1654544S45545455I994S499';
   static readonly ECRAN_MANDAT_SANTE = '988985545E4454M466454S6656551';
   static readonly ECRAN_CONTRAT_SANTE = '454545215485E444444C558555S66554';
   static readonly ECRAN_PROFIL_ACDS = '455455451122455E454548P152422112212A45448995';
   static readonly ECRAN_BESOIN_ACDS = '5545455545545E45454545455B455578455A465545';
   static readonly ECRAN_FORMULE_ACDS = '45545455452E45454555F4545545A54544554';
   static readonly ECRAN_RESUME_FORMULE_ACDS = '655565666E154555R4545525F454454A452221115';
   static readonly ECRAN_INFORMATIONS_ACDS = '111515554522221455E554545445I5545454A46445';
   static readonly ECRAN_SAUVEGARDE_INFORMATIONS_ACDS = '6965656E526565656S99998989I99899859A959495445';
   static readonly ECRAN_MANDAT_ACDS = '41152525555EM5555444A855245';
   static readonly ECRAN_CONTRAT_ACDS = '1231455E122235C58552255A225685';
   static readonly ECRAN_ESPACE_PROSPECT = '1113344555E55155112212255E558555155P51515';
   static readonly ECRAN_ESPACE_AGENT = '12552566E5663666555E88555222A963455652';
   static readonly ECRAN_LIST_DEVIS = '2155545E515515158L45645454D589964545';
   static readonly ECRAN_LIST_CONTRAT = '122155633566556E454521211212212L2122125554455C5542';
   static readonly ECRAN_RESUME_DEVIS = '11242552223E114425555R55454515D4545545545';
   static readonly ECRAN_RESUME_CONTRAT = '11112552558E555412R545412C612163666';
   static readonly CURRENT_SCREEN = '12357sggsgsh855C6666454552852652446fsgsgfS';
   static readonly QMS = '14221522Q11555M44444447S2225545';
   static readonly USER_TO_MODIF = '4651245455U452222T6569666566M54545452';
   static readonly SHOW_LOGIN = '112221S221222H542122W221212112L21122122O44555G45555I455555N55258';
   static readonly RANDOM_STRING = '1544122R4555515N45512D155151M1515555';
   static readonly FILTER_ERROR = '12220F1122I1222L112T445E555R774E778R885R555O445R5689';
   static readonly IS_CONNECTED = '122I222S4554C5553N3665T566D66';
   static readonly TRUE = '12222T14587R7855U669585E14551';
   static readonly FALSE = '12568F5566A7555L5654S778165E66555';
   // tslint:disable-next-line:max-line-length
   static readonly GEOLOCALISATION_URL = 'https://www.almerys.com/proximityResearch/RechercherPSProximite.do?dispatch=defaut&productId=00260&i=TEJQ';
}
