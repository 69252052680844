import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from 'src/app/app.config';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { GuardService } from 'src/app/shared/services/guard.service';

@Component({
  selector: 'app-list-contrats',
  templateUrl: './list-contrats.component.html',
  styleUrls: ['./list-contrats.component.css']
})
export class ListContratsComponent implements OnInit {

  constructor(private router: Router, private cookies: CookieService, private guardService: GuardService) { }

  listContracts: any = [];
  usurpationURL: any = null;
  isAgent = false;
  prospectError = false;
  @HostListener('click') onMouseClick() {
    if (this.prospectError) {
      this.prospectError = false;
    }
  }

  ngOnInit() {
    this.checkForCurrentScreen();
    if (this.cookies.get('agent') && JSON.parse(this.cookies.get('agent')) != null) {
      this.isAgent = true;
    }
    this.listContracts = JSON.parse(sessionStorage.getItem('listContrats'));
    this.usurpationURL =  this.cookies.get('usurpationURL');
    if (this.listContracts == null || this.listContracts === undefined) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.ESPACE_PROSPECT]);
    } else {
      setTimeout(() => {
         this.cookies.delete('listContrats');
         this.cookies.delete('usurpationURL');
      });
    }
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => { });
  }

  backToProspect() {
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.ESPACE_PROSPECT]);
  }

  consulterContrat(idDevis, produit, baseUrl) {
    if (baseUrl != null && baseUrl !== undefined) {
      if (this.isAgent) {
        setTimeout(() => {
          this.prospectError = true;
        });
      } else {
        this.accessPortailRedirect();
      }
    } else {
      const object = { 'idDevis': idDevis, 'produit': (produit === 'SANTE') ? OavConstants.PRODUIT_SANTE : OavConstants.PRODUIT_ACDS };
       this.cookies.set('resumeContrat', JSON.stringify(object), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
       this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_CONTRAT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.RESUME_CONTRAT]);
    }

  }

  accessPortailRedirect() {
    window.open(this.usurpationURL, 'blank');
  }

  trackByFn(index, item) {
    return index;
  }

}
