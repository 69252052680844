
import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';

@Directive({
  selector: '[stopClickEvent]'
})
export class CheckClickDirective  {
  constructor(
    private renderer: Renderer2, private elementref: ElementRef) {
    this.renderer.setStyle(this.elementref.nativeElement, 'pointer-events', 'none');

  }

}
