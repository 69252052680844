import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormulesService } from 'src/app/features/process-sante/services/formules.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { DevisService } from 'src/app/features/process-sante/services/devis.service';
import { DevisModelDTO } from 'src/app/features/process-sante/models/devisDTO';
import { ListFormuleDTO } from 'src/app/features/process-sante/models/listFormuleDTO';
import { FormuleCotisationGarantieDTO } from 'src/app/features/process-sante/models/formuleCotisationGarantieDTO';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { CallBakcDTO } from '../../models/CallBackDTO';
import { CourrierDTO } from '../../models/CourrierDTO';
import { DocumentStatiqueModel } from 'src/app/shared/models/documentStatiqueModel';
import { CookieService } from 'ngx-cookie-service';
import { DocumentStatiqueService } from 'src/app/shared/services/document-statique.service';
import { DevisStatus } from '../../models/devisStatus';
import { FormuleModelDTO } from '../../models/formuleDTO';
import { CodePromoDTO } from '../../models/codePromoDTO';
import { BdocDTO } from '../../models/bdocDTO';
import { BdocService } from '../../services/bdoc.service';
import { NumberFormatPipe } from 'src/app/shared/components/numberFormat/number.pipe';
import { GuardService } from 'src/app/shared/services/guard.service';
import { AppConfig } from 'src/app/app.config';
import { SanticlaireService } from 'src/app/shared/services/santiclaire.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SouscripteurService } from '../../services/souscripteur.service';
import { VilleService } from '../../services/ville.service';

@Component({
  selector: 'app-formules',
  templateUrl: './formules.component.html',
  styleUrls: ['./formules.component.css']
})

export class FormulesComponent implements OnInit {
  showFormules = true;
  showHelpFormule2 = false;
  showHelpFormule9 = false;
  formuleCotisation: any = [];
  devisId;
  formules = [];
  selectedFormuleCotisation;
  doctor = OavConstants.DOCTOR;
  pharma = OavConstants.PHARMA;
  plane = OavConstants.PLANE;
  hospi = OavConstants.HOSPI;
  dentaire = OavConstants.DENTAIRE;
  optique = OavConstants.OPTIQUE;
  sendMail = false;
  codesPromo = [];
  showErrorCode = false;
  immediatCall: any;
  rdv: any;
  tryToSubmitForm = false;
  resumeRDV = false;
  resumeImmediat = false;
  formuleSelected: any;
  disableCr = false;
  listDocumentStatique: DocumentStatiqueModel[] = [];
  disableEm = false;
  pdfDevis: String;
  parcours;
  produit;
  docsOpen = false;
  items = [{
    label: 'Mon Profil'
  },
  {
    label: 'Mes besoins'
  },
  {
    label: 'Formules'
  },
  {
    label: 'Votre devis'
  }
  ];
  activeIndex = 2;
  agentData;
  clientData;
  dataFormauleByTarif: any;
  codePromoValid;
  codePromoInValid;
  codePromoExist;
  bdocDto = new BdocDTO();
  data: any;
  changeStyle;
  showDocs;
  dataLoaded = false;
  agentCRC = false;
  isFakeEmail = false;
  modalRef: BsModalRef;
  devis: DevisModelDTO;
  tryToSubmitPopupForm = false;
  villesSouscripteur;
  cpSousNotExist;
  constructor(private formulesService: FormulesService, private spinner: NgxSpinnerService, private cookies: CookieService,
    private documentStatiqueService: DocumentStatiqueService, private bdocService: BdocService,
    private santiclaireService: SanticlaireService, private modalService: BsModalService,
    private souscripteurService: SouscripteurService, private villeService: VilleService,
    private devisService: DevisService, private router: Router, private guardService: GuardService) { }

  ngOnInit() {
    if (this.cookies.get('agent')) {
      const agent = JSON.parse(this.cookies.get('agent'));
      this.agentCRC = agent.profil === '53';
    }
    this.spinner.show();
    this.checkForCurrentScreen();
    window.scroll(0, 0);
    this.rdv = new CallBakcDTO();
    this.immediatCall = new CallBakcDTO();
    this.formuleSelected = new FormuleCotisationGarantieDTO();
    this.devisId = this.cookies.get('devis');
    this.parcours = this.cookies.get(OavConstants.PARCOURS);
    this.produit = this.cookies.get(OavConstants.PRODUIT);
    this.getFormule();
    this.getCodesPromo();
    this.getListDocumentStatique();
    if (this.cookies.get('agent')) {
      this.agentData = JSON.parse(this.cookies.get('agent'));
    }
    if (this.cookies.get('client')) {
      this.clientData = JSON.parse(this.cookies.get('client'));
    }
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => { });
  }

  getParcours() {
    if (this.parcours === 'MIXTE') {
      return 'Prospect';
    } else if (this.parcours === 'AGENT') {
      return 'Agent';
    } else {
      return null;
    }
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
  }

  openDocumentStatique(doc) {
    this.documentStatiqueService.getDocumentStatique(doc).subscribe(rep => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(this.base64ToBlob(rep.encoded));
      } else {
        const fileURL = URL.createObjectURL(this.base64ToBlob(rep.encoded));
        if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = doc;
          a.click();
          return;
        }
        window.open(fileURL);
      }
    });
  }

  getListDocumentStatique() {
    this.documentStatiqueService.getListDocumentsByProduitAndParcoursAndEcran('Sante', this.getParcours(), 'Mes formules').
      subscribe(rep => {
        this.listDocumentStatique = rep;
        if (this.listDocumentStatique.length > 0) {
          this.showDocs = true;
        }
      });
  }

  getFormule() {
    const tarifDTO = new ListFormuleDTO();
    this.spinner.show();
    this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
      tarifDTO.idDevis = this.devisId;
      this.devis = devis;
      if (this.devis.souscripteur.adressePostale.codePostale) {
        this.getSouscripteurVilles(this.devis.souscripteur.adressePostale.codePostale);
      }
      this.isFakeEmail = devis.user.fakeEmail;
      if (devis.dateSejourFrom) {
        tarifDTO.dateSejourFrom = this.convertDate(devis.dateSejourFrom);
      }
      if (devis.dateSejourTo) {
        tarifDTO.dateSejourTo = this.convertDate(devis.dateSejourTo);
      }
      tarifDTO.listIdFormule = this.formules;
      devis.idProduit = this.produit;
      devis.typeParcours = this.parcours;
      this.spinner.show();
      if (this.codePromoValid != null || this.codePromoValid !== undefined) {
        devis.codePromo = this.codePromoValid;
      }
      this.formulesService.getFormuleByTarif(devis).subscribe((data: Array<FormuleCotisationGarantieDTO>) => {

        this.createFormuleCotisation(data);
        if (this.formuleCotisation.length === 3) {
          this.dataLoaded = true;
          this.spinner.hide();
        }
      });
    });
  }

  private createFormuleCotisation(data) {
    this.formuleCotisation = [];
    data.forEach(element => {
      const formuleCot: any = {};
      formuleCot.familleActes = element.familleActes;
      formuleCot.formule = {
        recomanded: (element.formule.ordreRecommandation === OavConstants.OUI ? true : false),
        formuleLabel: element.formule.formuleLabel,
        id: element.formule.id,
        detailFile: element.formule.garantieSrc,
        description: element.formule.description,
        formulePrice: element.formule.formulePrice,
        formulePriceYear: element.formule.formulePrice,
        dateFinAdhesionString: element.formule.dateFinAdhesionString,
        showHelp: element.formule.showHelp,
        ipid: element.formule.ipidSrc,
        remboursement: element.formule.rbtSrc

      };
      formuleCot.formuleToSend = element;
      this.formuleCotisation.push(formuleCot);
    });
  }

  backViewTab() {
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_PROFIL_SANTE
      , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.set('previous', 'formule', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.BESOIN_SANTE_URL]);
  }

  adhesion(formuleCot) {
    this.spinner.show();
    this.showFormules = false;
    this.updateDevisFormule(formuleCot);
  }

  private updateDevisFormule(formuleCotisation) {
    this.devisService.getDevisById(this.devisId).subscribe((data: DevisModelDTO) => {
      const devis: any = data;
      this.dataFormauleByTarif = data;
      this.cookies.set('devis', String(devis.idDevis), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      devis.formule = formuleCotisation.formuleToSend.formule;
      if (this.sendMail) {
        devis.sendDevis = true;
      } else {
        devis.sendDevis = false;
      }
      devis.typeParcours = this.parcours;
      devis.idProduit = this.produit;
      devis.status = DevisStatus.DEVIS;
      this.spinner.show();
      this.devisService.updateDevisFormule(devis).subscribe(res => {
        this.bdocDto.devisId = devis.idDevis;
        this.bdocDto.sendToGED = true;
        this.bdocService.getFileFromBdoc(this.bdocDto).subscribe((obj) => {
          this.spinner.hide();
          this.cookies.set('previous', 'formule', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_FORMULE_SANTE
            , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          this.router.navigate([OavConstants.RESUME_SANTE_URL]);
        }, error => {
          this.spinner.hide();
        });
      }, error => {
        this.spinner.hide();
      });
    }, error => {
      this.spinner.hide();
    });
  }

  private convertDate(inputFormat) {
    const d = new Date(inputFormat);
    return [this.pad(d.getDate()), this.pad(d.getMonth() + 1), d.getFullYear()].join('/');
  }

  private pad(s) { return (s < 10) ? '0' + s : s; }

  updateTarif(codeCom) {
    const result = this.codesPromo.filter(code => code === (codeCom));
    this.codePromoInValid = false;
    if (codeCom.length === 0) {
      this.showErrorCode = false;
    } else {
      if (result.length > 0) {
        this.showErrorCode = false;
        this.verifCode(codeCom);
      } else {
        this.showErrorCode = true;
      }
    }
  }

  sendDevis(fc) {
    this.sendMail = true;
    this.formuleSelected = fc;
    this.updateDevisFormule(this.formuleSelected);
  }

  createAudit(pdf) {
    const devis = new DevisModelDTO();
    devis.idDevis = this.devisId;
    devis.actionAudit = pdf;
    this.devisService.createAudit(devis).subscribe(res => {
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });

  }
  getCodesPromo() {
    this.formulesService.getListCodes(this.devisId, 1).subscribe(res => {
      this.codesPromo = res as [];
    }, error => {
      console.error('error when get list of code promo');
    });
  }

  saveFormule(fc) {
    this.formuleSelected = fc;
    this.devisService.getDevisById(this.devisId).subscribe((data: DevisModelDTO) => {
      const devis: any = data;
      this.dataFormauleByTarif = data;
      this.cookies.set('devis', String(devis.idDevis), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      devis.formule = this.formuleSelected.formuleToSend.formule;
      devis.sendDevis = false;
      devis.typeParcours = this.parcours;
      devis.idProduit = this.produit;
      devis.status = DevisStatus.DEVIS;
      this.devisService.updateDevisFormule(devis).subscribe(res => {
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
    }, error => {
      this.spinner.hide();
    });
    const ddevis = new DevisModelDTO();
    ddevis.idDevis = this.devisId;
    ddevis.formule = this.formuleSelected.formuleToSend.formule;
  }

  consultFile(form, type) {
    let formule = new FormuleModelDTO();
    formule = form;
    formule.fileToDownload = type;
    const devis = new DevisModelDTO();
    devis.formule = formule;
    devis.idDevis = this.devisId;
    this.formulesService.getPdf(devis).subscribe((data: ArrayBuffer) => {
      const newBlob = new Blob([data], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
      } else {
        const dataa = window.URL.createObjectURL(newBlob);
        if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = dataa;
          a.download = type;
          a.click();
          return;
        }
        window.open(dataa);
      }
    }, error => {
      this.spinner.hide();
    });
  }
  verifCode(codePromo) {
    const promo = new CodePromoDTO();
    promo.code = codePromo;
    promo.listFormules = this.formuleCotisation;
    promo.idDevis = this.devisId;
    promo.idProduit = this.produit;
    this.formulesService.verifValidCode(promo).subscribe(res => {
      this.codePromoExist = res as boolean;
      if (this.codePromoExist) {
        this.codePromoValid = codePromo;
        this.getFormule();
        window.scroll(0, 0);
      } else {
        this.codePromoInValid = true;
      }
    }, error => {
      this.codePromoExist = false;
    });

  }
  consulterDevis(fc) {
    this.changeStyle = true;
    this.spinner.show();
    this.formuleSelected = fc;
    this.devisService.getDevisById(this.devisId).subscribe((data: DevisModelDTO) => {
      this.spinner.show();
      const devis: any = data;
      devis.formule = this.formuleSelected.formuleToSend.formule;
      devis.sendDevis = false;
      devis.typeParcours = this.parcours;
      devis.idProduit = this.produit;
      devis.status = DevisStatus.DEVIS;
      devis.showPdfDevis = true;
      this.devisService.updateDevisFormule(devis).subscribe(res => {
        this.devisId = devis.idDevis;
        this.bdocDto.devisId = this.devisId;
        this.bdocService.getFileFromBdoc(this.bdocDto).subscribe(result => {
          this.data = result;
          if (window.navigator.msSaveOrOpenBlob) {
            this.handeIE11DownloadFile(this.data.bdocReponse.documentb64, 'Devis.pdf');
          } else {
            const a = document.createElement('a');
            a.href = 'data:application/pdf;base64,' + this.data.bdocReponse.documentb64;
            a.download = 'Devis.pdf';
            a.click();
          }
          this.spinner.hide();
        }, error => {
        });
      }, error => {
        this.spinner.hide();
      });
    }, error => {
      this.spinner.hide();
    });
  }

  handeIE11DownloadFile(data64, name) {
    window.navigator.msSaveBlob(this.base64ToBlob(data64), name);
  }

  trackByFn(index, item) {
    return index;
  }

  openGeolocalisation() {
    this.santiclaireService.getGeoclaireUrl().subscribe((data: any) => {
      window.open(data.location, '_blank');
    }, error => {
      console.error('error when get santiclaire');
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  testForBlackSpaces(value) {
    const temp = value;
    if (temp) {
      return temp.toString().replace(/\s/g, '');
    }
    return false;
  }

  getSouscripteurVilles(codePostal) {
    if (codePostal && codePostal.length === 5) {
      this.villeService.getByCodePostal(codePostal).subscribe(data => {
        this.villesSouscripteur = data;
        this.devis.souscripteur.adressePostale.ville = this.villesSouscripteur.length && !this.devis.souscripteur.adressePostale.ville
        ? this.villesSouscripteur[0].libelle : this.devis.souscripteur.adressePostale.ville;
        this.cpSousNotExist = this.villesSouscripteur.length === 0;
      }, error => {
        this.cpSousNotExist = true;
        console.error('error while get villes souscripteur');
      });
    } else {
      this.cpSousNotExist = true;
    }
  }

  updateSouscripteurData(souscripteur): void {
    this.tryToSubmitPopupForm = true;
    if (this.validSouscripteurInfo(souscripteur)) {
      this.spinner.show();
      this.souscripteurService.updateSouscripteur(souscripteur).subscribe(data => {
        this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
          this.devis = devis;
          this.modalRef.hide();
          this.spinner.hide();
        }, error => {
          console.error('error when get devis by id');
        });
      }, error => {
        console.error('error when update souscripteur');
      });
    }
  }

  cancelUpdateSouscripteurData() {
    this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
      this.devis = devis;
      this.modalRef.hide();
    }, error => {
      console.error('error when get devis by id');
    });
  }

  private validSouscripteurInfo(souscripteur) {
    return souscripteur.adressePostale.adresseLigne1 && this.testForBlackSpaces(souscripteur.adressePostale.adresseLigne1)
      && this.validCodePostal(souscripteur.adressePostale.codePostale) && this.testForBlackSpaces(souscripteur.adressePostale.ville) &&
      souscripteur.adressePostale.ville;
  }

  private validCodePostal(code) {
    const re = /^\d*/;
    return code && re.test(String(code)) && code.length > 4;
  }

}
