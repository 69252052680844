export interface IAppConfig {
    securedEndpoint: string;
    publicEndpoint: string;
    cookieSecure: boolean;
}

/**
 *  The cookieSecure : should be true in server environement and undefined in local environement
 */
export const AppConfig: IAppConfig = {

    /*************************************** Local **************************** */
      // publicEndpoint: 'http://localhost:8080',
      //  securedEndpoint: 'http://localhost:8080/oav/ws/rest',
      // cookieSecure: undefined

    /*************************************** Recette ******************/
    //  publicEndpoint: 'https://ws-oav-cep-rec.owliance.net/oav',
    //  securedEndpoint: 'https://ws-oav-cep-rec.owliance.net/oav/oav/ws/rest',
    //  cookieSecure: true

    /*************************************** PROD ******************/
    publicEndpoint: 'https://espace-devis.iassure.fr/oav',
    securedEndpoint: 'https://espace-devis.iassure.fr/oav/oav/ws/rest',
    cookieSecure: true


};
