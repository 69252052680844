import { FormuleModelDTO } from './formuleDTO';
import { Beneficiaire } from './beneficiaire';
import { Adherent } from './adherent';
import { OauthModel } from '../../../shared/models/oauthModel';
import { RefBancaireModelDTO } from './refBancaireDTO';
import { SouscripteurModelDTO } from './souscripteurModelDTO';
import { Assureur } from './assureur';
import { AdressePostalDTO } from './adressePostalDTO';
import { ContactDTO } from './contactDTO';
import { UserDTO } from './userDTO';


export class DevisModelDTO {
    idDevis?: number;
    dateAdhesion?: Date;
    dateSejourFrom?: string;
    dateSejourTo?: string;
    beneficiaires?: Array<Beneficiaire>;
    formule?: FormuleModelDTO;
    modePaiement?: string;
    oauth: OauthModel;
    sendDevis?: Boolean;
    actionAudit?: string;
    idProduit?: number;
    typeParcours: string;
    pdfDevis?: string;
    vente?: string;
    status?: string;
    refBancaire?: RefBancaireModelDTO;
    souscripteur?: SouscripteurModelDTO;
    codePromo?: string;
    contratConjoint?: string;
    qmsAccepted?: boolean;
    assureur?: Assureur;
    refDevis?: string;
    valDevis?: number;
    dateFinDevis?: Date;
    refContrat?: string;
    showPdfDevis?: Boolean;
    numDevis?: string;
    idRHApporteur?: string;
    dateDerniereModification: Date;
    user?: UserDTO;
    modeEnvoi?: string;
    typeSignature?: string;
    receivedDocs?: boolean;
    adressePostale?: AdressePostalDTO;
    contact?: ContactDTO;
    assure?: boolean;
    resiliation?: boolean;
    libelleCommercial?: string;
    constructor() {
        this.beneficiaires = new Array<Beneficiaire>();
        this.formule = new FormuleModelDTO();
        this.oauth = new OauthModel();
        this.refBancaire = new RefBancaireModelDTO();
        this.souscripteur = new SouscripteurModelDTO();
        this.user = new UserDTO();
        this.adressePostale = new AdressePostalDTO();
        this.contact = new ContactDTO();
    }
}
