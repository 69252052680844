import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberWhiteSpaceDetector'
})
export class NumberWhiteSpaceDetectorPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value != null) {
      value = value.replace(/[^\dA-Z]/g, '').replace(/(.{2})/g, '$1 ').trim();
    }
    return value;
  }

}
