import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from 'src/app/app.config';
import { CookiesEntriesModel } from 'src/app/shared/models/cookiesEntries.Model';

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.css']
})
export class MentionsLegalesComponent implements OnInit {

  constructor(private cookies: CookieService) { }

  ngOnInit() {
    if (this.cookies.get('mlg')) {
      if (this.cookies.get('mlg') === 'personal data') {
        this.scrollToElement('pdata');
      } else if (this.cookies.get('mlg') === 'mentions legales') {
        this.scrollToElement('mgl');
      } else if (this.cookies.get('mlg') === 'cookies') {
        this.scrollToElement('cookies');
      }
    }
  }

  private scrollToElement(id) {
    document.getElementById(id).scrollIntoView(true);
  }

}
