import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoginCredentials } from 'src/app/core/models/loginObj';
import { UserService } from 'src/app/core/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDTO } from 'src/app/core/models/userDTO';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ClientDataDTO } from '../../models/clientData';
import { AgentDataDTO } from '../../models/agentData';
import { EncryptionDecryptionService } from '../../../shared/services/encryption-decryption.service';
import { AuthenticationUtilitiesService } from '../../services/authenticationUtilities.service';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
  showLogin = true;
  bsModalRef: BsModalRef;
  recaptcha = false;
  user = new UserDTO();
  //siteKey = '6LcA-uEUAAAAAHERpRri7dPKJVG-_u3RM5gtYaj4';
  siteKey = '6LcdJU8eAAAAAPV3bXGSWV92Roql3Ks1wzILBEzf';
  loginFailed = false;
  username;
  unknownId = false;
  credentials = new LoginCredentials();
  agentData = new AgentDataDTO();
  clientData = new ClientDataDTO();
  tryToReinit = false;
  responseLogin: any;
  accountExpired = false;
  accountLocked = false;
  accountInactif = false;
  sendReinitPwdMail = false;
  getUserConnectedResponse;
  size: string;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.size = (event.target.innerWidth > 465) ? 'normal' : 'compact';
  }
  constructor(private modalService: BsModalService,
    private authService: AuthService,
    private userService: UserService, private spinner: NgxSpinnerService, private router: Router, private cookies: CookieService,
    private encryptionDecryptionService: EncryptionDecryptionService,
    private authUtilities: AuthenticationUtilitiesService) {
    this.hideCallBackComponent();
  }

  ngAfterViewInit() {
    this.size = (window.screen.width > 465) ? 'normal' : 'compact';
  }

  ngOnInit() {
    this.cookies.delete('produit');
    this.cookies.delete('user');
    this.cookies.delete('devis');
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    if (this.cookies.get('email')) {
      this.credentials.username = this.cookies.get('email');
      this.cookies.delete('email');
    }
    if (this.cookies.get(OavConstants.SHOW_LOGIN)) {
      this.showLogin = false;
    }
    this.spinner.hide();
  }

  hideCallBackComponent() {
    document.getElementById('sectionDevis').style.display = 'none';
  }

  forgetPassword() {
    this.showLogin = false;
  }

  backToLogin() {
    this.cookies.delete(OavConstants.SHOW_LOGIN);
    this.showLogin = true;
    this.accountInactif = false;
  }

  renitPwd() {
    this.tryToReinit = true;
    this.accountInactif = false;
    this.unknownId = false;
    this.sendReinitPwdMail = false;
    this.cookies.delete(OavConstants.SHOW_LOGIN);
    if (this.username && this.recaptcha) {
      this.spinner.show();
      // Remove this call (we jsut need the username to send the mail)
      this.userService.checkIfUserFound(this.username).subscribe((result: boolean) => {
        if (result) {
          this.sendRenitPwdMail(this.username);
        } else {
          this.unknownId = true;
        }
        this.spinner.hide();
      }, error => {
        if (error.errorCode === 18) {
          this.accountInactif = true;
        }
        this.spinner.hide();
      });
    }
  }

  sendRenitPwdMail(username) {
    this.userService.renitPass(username).subscribe(data => {
      this.sendReinitPwdMail = true;
      // this.showLogin = true;
      this.router.navigate(['/mail-envoyé']);
    }, error => {
      this.spinner.hide();
    });
  }

  showInfoIdentifiant(template) {
    this.bsModalRef = this.modalService.show(template, { class: 'modal-md' });
  }

  authenticate() {
    this.loginFailed = false;
    this.accountExpired = false;
    this.accountLocked = false;
    if (this.credentials.password && this.credentials.username) {
      const credentialsToLog: LoginCredentials = Object.assign({}, this.credentials);
      credentialsToLog.password = this.encryptionDecryptionService.encryptPassword(this.credentials.password);
      this.spinner.show();
      this.authService.login(credentialsToLog).subscribe((data) => {
        this.responseLogin = data;
        this.authUtilities.clearStorage();
        this.authUtilities.updateCookie(data);
        this.authUtilities.processAfterConnection(true,false);
      }, error => {
        this.onLoginFail(error);
        this.spinner.hide();
      });
    } else {
      this.loginFailed = true;
    }
  }

  onLoginFail(error) {
    if (error) {
      if (error.errorCode === 19) {
        this.accountExpired = true;
      } else if (error.errorCode === 12) {
        // Account locked
        this.accountLocked = true;
      } else if (error.errorCode === 13) {
        // Password expired
        this.cookies.set(OavConstants.USER_TO_MODIF, this.credentials.username,
          undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate(['/reinitialisation-password']);
      } else {
        this.loginFailed = true;
      }
    } else {
      this.loginFailed = true;
    }
  }

  handleSuccess(event) {
    this.recaptcha = true;
  }

  handleLoad() {
    this.recaptcha = false;
  }

  handleExpire() {
    this.recaptcha = false;
  }

  handleReset() {
    this.recaptcha = false;
  }
}
