import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { OavUrlConstants } from '../data/OavURLConstants';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  publicUri = AppConfig.securedEndpoint + OavUrlConstants.PAYMENT_ENDPOINT;

  constructor(private http: HttpClient) { }

  getPaymentInfo(data) {
    return this.http.post(this.publicUri + OavUrlConstants.CR, data);
  }

  getLatestByDevis(devis) {
    return this.http.get(this.publicUri + OavUrlConstants.LATEST_PAYMENT_BY_DEVIS + '/' + devis);
  }
}
