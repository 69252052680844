import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DocumentStatiqueService } from 'src/app/shared/services/document-statique.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
declare let gtag: Function;
@Component({
  selector: 'app-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.css']
})
export class CookiesBannerComponent implements OnInit, AfterViewInit {

  constructor(private documentStatiqueService: DocumentStatiqueService, private cookies: CookieService,
    private router: Router) { }

  acceptCookies: boolean;
  answer = false;
  listDocumentStatique = [];

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.cookies.get('accept-cookies') &&
      ((this.cookies.get('accept-cookies') === 'true') || (this.cookies.get('accept-cookies') === 'false'))) {
      setTimeout(() => {
        this.answer = true;
      });
    } else {
      setTimeout(() => {
        this.answer = false;
      });
    }
  }

  acceptCookiesFn() {
    this.cookies.set('accept-cookies', 'true', 30 * 13, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.acceptCookies = true;
    this.answer = true;
    gtag('config', 'UA-111105258-4', {
      'cookie_expires': 13 * 30 * 24 * 60 * 60,
      'page_path': this.router.url,
      'pagePath': undefined
    }
    );
  }

  refuseCookiesFn() {
    this.cookies.set('accept-cookies', 'false', 30 * 13, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.acceptCookies = false;
    this.answer = true;
  }

  checkMl() {
    window.open('/#/cookies', '_blank');
  }

  openDocumentStatique(doc) {
    this.documentStatiqueService.getDocumentStatique(doc).subscribe(rep => {
      const fileURL = URL.createObjectURL(this.base64ToBlob(rep.encoded));
      if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = doc;
        a.click();
        return;
      }
      window.open(fileURL);

    });
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: 'application/pdf' });
  }
}
