import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfig } from 'src/app/app.config';
import { PaymentRequest } from 'src/app/core/models/payment-request';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { DevisModelDTO } from '../../models/devisDTO';
import { DevisStatus } from '../../models/devisStatus';
import { DevisService } from '../../services/devis.service';
import { SignatureService } from '../../services/signature.service';

@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.css']
})
export class PaiementComponent implements OnInit {
  paymentRequest = new PaymentRequest();
  @ViewChild('paiementForm') paiementForm: ElementRef;
  devisId;

  constructor(private paymentService: PaymentService, private spinner: NgxSpinnerService, private cookies: CookieService,
    private router: Router, private devisService: DevisService, private signatureService: SignatureService) { }

  ngOnInit() {
    this.spinner.show();
    this.devisId = this.cookies.get(OavConstants.DEVIS);
    if (this.router.url.includes('REJECT')) {
      this.updateDevisStatus(DevisStatus.MANDAT);
      this.updatePackageTessiSignStatus('Rejected');
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.INFORMATION_SANTE_URL
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.MANDAT_SIGNATURE_SANTE_URL]);
    } else if (this.router.url.includes('SIGNED')) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_MANDAT_SANTE
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.updatePackageTessiSignStatus('Finished');
      this.initPaymentForm();
    } else {
      this.updateDevisStatus(DevisStatus.MANDAT);
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.INFORMATION_SANTE_URL
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.MANDAT_SIGNATURE_SANTE_URL]);
    }
  }

  private updateDevisStatus(status) {
    this.devisService.updateDevisStatus(this.devisId, status).subscribe((data: DevisModelDTO) => {
    }, error => {
      console.error('Error when update devis status');
      this.spinner.hide();
    });
  }

  private updatePackageTessiSignStatus(status) {
    this.signatureService.updatePackageTessiSignStatus(this.devisId, status).subscribe((data: DevisModelDTO) => {
    }, error => {
      console.error('Error when update tessi sign package status');
      this.spinner.hide();
    });
  }

  initPaymentForm() {
    this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
      if (devis.refBancaire.premiereCotisation === 'CB') {
        this.paymentRequest.vads_trans_date = this.createTransactionDate();
        this.paymentRequest.idDevis = this.cookies.get(OavConstants.DEVIS);
        this.paymentService.getPaymentInfo(this.paymentRequest).subscribe((data: PaymentRequest) => {
          this.paymentRequest = data;
          console.log('request = ', this.paymentRequest);
          this.cookies.set(OavConstants.PRODUIT, 'SANTE', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          setTimeout(() => {
            this.paiementForm.nativeElement.submit();
            this.spinner.hide();
          }, 2000);
        }, error => {
          this.spinner.hide();
          console.error('error when get payment info');
        });
      } else {
        this.spinner.hide();
        this.router.navigate([OavConstants.VALIDATION_SANTE_URL]);
      }
    }, error => {
      this.spinner.hide();
      console.error('error when get devis by id');
    });
  }

  private createTransactionDate(): string {
    const date = new Date();
    return date.getFullYear().toString() + this.convertToString(date.getMonth() + 1) + this.convertToString(date.getDate()) +
      this.convertToString(date.getHours()) + this.convertToString(date.getMinutes()) + this.convertToString(date.getSeconds());
  }

  private convertToString(nb: number): string {
    return nb > 9 ? nb.toString() : '0' + nb.toString();
  }

}
