import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import { EspaceProspectService } from 'src/app/core/services/espace-prospect.service';
import { DevisStatus } from 'src/app/features/process-sante/models/devisStatus';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfig } from 'src/app/app.config';
import { GuardService } from 'src/app/shared/services/guard.service';

@Component({
  selector: 'app-list-devis',
  templateUrl: './list-devis.component.html',
  styleUrls: ['./list-devis.component.css']
})
export class ListDevisComponent implements OnInit {
  listDevis: any = [];
  isAgent = false;
  listDevisDisplay: any = [];
  user;

  constructor(private router: Router, private cookies: CookieService, private translate: TranslateService,
    private guardService: GuardService, private modalService: ModalService, private espaceProspectService: EspaceProspectService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.checkForCurrentScreen();
    if (this.cookies.get('agent') && JSON.parse(this.cookies.get('agent')) != null) {
      this.isAgent = true;
    }
    if (this.cookies.get('prospect')) {
      this.user = JSON.parse(this.cookies.get('prospect'));
    }
    this.listDevis = JSON.parse(sessionStorage.getItem('listDevis'));
    if (this.listDevis === null || this.listDevis === undefined) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.ESPACE_PROSPECT]);
    } else {
      setTimeout(() => {
         this.cookies.delete('listDevis');
      });
    }
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => { });
  }

  ConsulterDevis(devis) {
    const object = {
      'idDevis': devis.idDevis,
      'expired': this.expired(devis.dateFinDevis),
      'produit': (devis.formule) ? devis.formule.produit : OavConstants.PRODUIT_SANTE
    };
    this.cookies.set('resumeDevis', JSON.stringify(object), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_DEVIS
      , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.RESUME_DEVIS]);
  }

  backToProspect() {
    this.cookies.delete('prospect');
    sessionStorage.removeItem('listDevis');
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.ESPACE_PROSPECT]);
  }

  dateDefaultFormat(input) {
    return moment(input).format('DD/MM/YYYY');
  }

  expired(dateFinDevis) {
    if (moment().isAfter(moment(dateFinDevis))) {
      return true;
    }
    return false;
  }

  continueAdhesion(idDevis, produit) {
     this.cookies.set(OavConstants.DEVIS, idDevis, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    if (produit === OavConstants.PRODUIT_ACDS) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_FORMULE_ACDS
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.INFORMATION_ACDS_URL]);
    } else if (produit === OavConstants.PRODUIT_SANTE) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_FORMULE_SANTE,
        undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.INFORMATION_SANTE_URL]);
    }
  }

  SupprimerDevis(idDevis) {
    let btnOui: string;
    let btnNon: string;
    let confirmation: string;
    let confirmMsg: string;
    this.translate.get('espaceProspect.oui').subscribe((res: string) => {
      btnOui = res;
    });
    this.translate.get('espaceProspect.non').subscribe((res: string) => {
      btnNon = res;
    });
    this.translate.get('espaceProspect.confirmHeader').subscribe((res: string) => {
      confirmation = res;
    });
    this.translate.get('espaceProspect.confirmMsg').subscribe((res: string) => {
      confirmMsg = res;
    });
    this.modalService.confirm(
      confirmation,
      confirmMsg,
      [btnOui, btnNon],
      'danger')
      .subscribe((answer) => {
        this.spinner.show();
        if (answer === 'Oui' || answer === 'Yes') {
          this.espaceProspectService.updateDevisStatus(idDevis, 'Abandonne').subscribe(rep => {
            this.loadDevisClient(this.user.id);
            this.listDevis = this.listDevis.filter(e => e.idDevis.toString() !== idDevis.toString());
          }, error => {
            this.spinner.hide();
            console.error('error when update devis status');
          });
        }
      });
  }

  private loadDevisClient(idUser) {
    this.espaceProspectService.getDevisByIdUser(idUser).subscribe(result => {
      this.listDevisDisplay = result;
      this.listDevis = this.listDevisDisplay.filter(e => (e.status === 'Devis' || e.status === 'Abandonne' ||
        (e.status === 'A signer' && this.expired(e.dateFinDevis)) ||
        (e.status === 'Information' && this.expired(e.dateFinDevis)) ||
        (e.status === 'Mandat' && this.expired(e.dateFinDevis))
      ));
      this.listDevis.sort((a, b) => {
        if (moment(a.dateDevis) > moment(b.dateDevis)) {
          return -1;
        } else if (moment(a.dateDevis) < moment(b.dateDevis)) {
          return 1;
        } else {
          return 0;
        }
      });
      sessionStorage.setItem('listDevis', JSON.stringify(this.listDevis));
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.error('error when reset devis list')
    });
  }


  trackByFn(index, item) {
    return index;
  }

}
