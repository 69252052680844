import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AgentDataDTO } from '../../models/agentData';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { SearchParamsModel } from '../../models/searchParamsModel';
import { EspaceAgentService } from '../../services/espace-agent.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { VilleDTO } from 'src/app/features/process-sante/models/villeDTO';
import { VilleService } from 'src/app/features/process-sante/services/ville.service';
import * as moment from 'moment';
import { OavDateService } from 'src/app/shared/services/oav-date.service';
import { UserDTO } from '../../models/userDTO';
import { UserService } from '../../services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { ClientDataDTO } from '../../models/clientData';
import { Router } from '@angular/router';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { AppConfig } from 'src/app/app.config';
import { GuardService } from 'src/app/shared/services/guard.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-espace-agent',
  templateUrl: './espace-agent.component.html',
  styleUrls: ['./espace-agent.component.css']
})
export class EspaceAgentComponent implements OnInit {

  constructor(private agentService: EspaceAgentService, private spinner: NgxSpinnerService,
    private villeService: VilleService, private dateService: OavDateService,
    private userService: UserService, private localeService: BsLocaleService,
    private cookies: CookieService, private router: Router, private guardService: GuardService) {
    frLocale.invalidDate = '';
    defineLocale('fr', frLocale);
    this.localeService.use('fr');
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', isAnimated: true, showWeekNumbers: false });
    this.showCallBackComponent();
  }

  searchOn = false;
  codeNotExists = true;
  validCodePostal = false;
  dateNaissance = null;
  dateNaissanceInvalide = false;
  bsConfig: Partial<BsDatepickerConfig>;
  searchParams: SearchParamsModel = new SearchParamsModel();
  villes;
  searchResultList;
  listToDisplay = [];
  tryToSubmitForm = false;
  agentData: AgentDataDTO;
  dateNaissancePickDirty;
  contentArrayLength;
  currentPage = 1;
  elementPerPage = 5;
  maxPage = 13;


  ngOnInit() {
    console.log('this.cookies.get(OavConstants.CURRENT_SCREEN) = ', this.cookies.get(OavConstants.CURRENT_SCREEN));
    this.checkForCurrentScreen();
    this.cookies.delete('devis');
    if (this.cookies.get('agent') && JSON.parse(this.cookies.get('agent')) != null) {
      this.agentData = JSON.parse(this.cookies.get('agent'));
    }
    this.getVilles();
    if (this.cookies.get('searchParams')) {
      this.currentPage = JSON.parse(this.cookies.get('searchParams')).request.pageNumber;
      this.elementPerPage = JSON.parse(this.cookies.get('searchParams')).request.pageSize;
      this.spinner.show();
      this.getUSers(JSON.parse(this.cookies.get('searchParams')));
      this.dateNaissance = null;
      Object.keys(JSON.parse(this.cookies.get('searchParams'))).forEach(key => {
        if (key === 'dateNaissance' && JSON.parse(this.cookies.get('searchParams'))[key] != null &&
          JSON.parse(this.cookies.get('searchParams'))[key] !== undefined) {
          this.dateNaissance = new Date(JSON.parse(this.cookies.get('searchParams'))[key]);
        }
        this.searchParams[key] = JSON.parse(this.cookies.get('searchParams'))[key];
      });
    }
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => { });
  }

  showCallBackComponent() {
    document.getElementById('sectionDevis').style.display = 'block';
  }

  verifCodePostal(codePostal) {
    this.codeNotExists = false;
    this.villeService.getByCodePostal(codePostal).subscribe(data => {
      this.villes = data;
      if (this.villes && this.villes.length > 0) {
        this.codeNotExists = true;
      } else {
        this.codeNotExists = false;
      }
    }, error => {
      this.codeNotExists = false;
    });
  }

  getVilles() {
    this.villeService.getAll().subscribe((data: Array<VilleDTO>) => {
      this.villes = data;
    });
  }

  private inlineParams() {
    Object.keys(this.searchParams).forEach(key => {
      if (this.searchParams[key] === '') {
        this.searchParams[key] = null;
      }
    });
  }

  search(formInvalid) {
    this.tryToSubmitForm = true;
    this.searchOn = false;
    if (!formInvalid) {
      this.spinner.show();
      this.inlineParams();
      this.searchResultList = [];
      this.searchParams.request.pageNumber = 1;
      this.currentPage = 1;
      if (this.dateNaissance == null) {
        this.searchParams.dateNaissance = null;
      } else {
        this.searchParams.dateNaissance = this.dateService.saveUTCDate(this.dateNaissance);
      }
      this.searchParams.countDetection = true;
      if (this.searchParams.statusDevis || this.searchParams.statusEncoursTransfo ||
        this.searchParams.statusPending || this.searchParams.statusAbandonne) {
        this.getUSers(this.searchParams);
      } else {
        this.searchOn = true;
        this.spinner.hide();
      }
    }
  }

  getUSers(searchParams) {
    this.initSearchParams(searchParams);
    searchParams.request.pageSize = this.elementPerPage;
    this.agentService.searchUser(searchParams).subscribe(decoy => {
      this.searchResultList = decoy;
      this.searchResultList.listUser.forEach(element => {
        this.setStatus(element);
      });
      this.searchOn = true;
      this.contentArrayLength = this.searchResultList.totalElement;
      this.cookies.set('searchParams', JSON.stringify(searchParams), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.spinner.hide();
    }, error => {
      console.error('error when loading list of users');
      this.spinner.hide();
    });
  }

  private initSearchParams(searchParams) {
    searchParams.nom = searchParams.nom === '' || !searchParams.nom  ? null : searchParams.nom;
    searchParams.prenom = searchParams.prenom === '' || !searchParams.prenom ? null : searchParams.prenom;
    searchParams.numDevis = searchParams.numDevis === '' || !searchParams.numDevis ? null : searchParams.numDevis;
    searchParams.numContrat = searchParams.numContrat === '' || !searchParams.numContrat  ? null : searchParams.numContrat;
    searchParams.dateNaissance = searchParams.dateNaissance === '' || !searchParams.dateNaissance ? null : searchParams.dateNaissance;
    searchParams.email = searchParams.email === '' || !searchParams.email ? null : searchParams.email;
  }


  private setStatus(item) {
    for (let index = 0; index < item.listDevis.length; index++) {
      if (item.listDevis[index].produit === OavConstants.PRODUIT_SANTE_ID.toString()) {
        if (item.listDevis[index].status === 'Pending') {
          item.santeStatusPending = true;
          break;
        } else if ((item.listDevis[index].status.toString() === 'Information' ||
          item.listDevis[index].status.toString() === 'PJ en cours' || item.listDevis[index].status.toString() === 'A signer'
          || item.listDevis[index].status.toString() === 'A payer' || item.listDevis[index].status.toString() === 'Mandat')
          && !(this.expired(item.listDevis[index].dateFinDevis))) {
          item.santeStatusTransfo = true;
        } else if (item.listDevis[index].status.toString() === 'Devis' && !(this.expired(item.listDevis[index].dateFinDevis))) {
          item.santeDevis = true;
        } else if (item.listDevis[index].status.toString() === 'Abandonne') {
          item.santeStatusAbandonne = true;
        } else if (this.expired(item.listDevis[index].dateFinDevis) && item.listDevis[index].status.toString() !== 'Abandonne'
          && item.listDevis[index].status.toString() !== 'En gestion'
          && item.listDevis[index].status.toString() !== 'A envoyer en gestion' &&
          item.listDevis[index].status.toString() !== 'Envoye en gestion') {
          item.santeExpire = true;
        }
      }
    }
    for (let index = 0; index < item.listDevis.length; index++) {
      if (item.listDevis[index].produit === OavConstants.PRODUIT_ACDS_ID.toString()) {
        if (item.listDevis[index].status.toString() === 'Pending') {
          item.acdsStatusPending = true;
          break;
        } else if ((item.listDevis[index].status.toString() === 'Information' ||
          item.listDevis[index].status.toString() === 'PJ en cours' || item.listDevis[index].status.toString() === 'A signer'
          || item.listDevis[index].status.toString() === 'A payer' || item.listDevis[index].status.toString() === 'Mandat')
          && !(this.expired(item.listDevis[index].dateFinDevis))) {
          item.acdsStatusTransfo = true;
        } else if (item.listDevis[index].status.toString() === 'Devis' && !(this.expired(item.listDevis[index].dateFinDevis))) {
          item.acdsDevis = true;
        } else if (item.listDevis[index].status.toString() === 'Abandonne') {
          item.acdsStatusAbandonne = true;
        } else if (this.expired(item.listDevis[index].dateFinDevis) && item.listDevis[index].status.toString() !== 'Abandonne'
          && item.listDevis[index].status.toString() !== 'En gestion'
          && item.listDevis[index].status.toString() !== 'A envoyer en gestion'
          && item.listDevis[index].status.toString() !== 'Envoye en gestion') {
          item.acdsExpire = true;
        }
      }
    }
  }


  private expired(dateFinDevis) {
    if (moment().isAfter(moment(dateFinDevis))) {
      return true;
    }
    return false;
  }

  renitPwd(user) {
    this.spinner.show();
    this.userService.getUserByUsername(user.email).subscribe((res: UserDTO) => {
      this.sendRenitPwdMail(user.email);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  sendRenitPwdMail(user) {
    this.userService.renitPass(user).subscribe(data => {
    }, error => {
      this.spinner.hide();
    });
  }

  checkClient(clickedClient) {
    const client = new ClientDataDTO();
    client.nom = clickedClient.lastName;
    client.prenom = clickedClient.firstName;
    client.email = clickedClient.email;
    client.clientUid = clickedClient.activationId;
    this.cookies.set('client', JSON.stringify(client), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.set('scrolly', Math.abs(document.body.getBoundingClientRect().top).toString(),
    undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
      , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.ESPACE_PROSPECT]);
  }

  initSearchParamsBtn() {
    this.searchParams = new SearchParamsModel();
    this.dateNaissance = null;
    this.cookies.delete('client');
  }


  controlDate(date) {
    const temp = new Date(date).getFullYear() >= 1523;
    return (new Date(date).toString() !== 'Invalid Date' && temp);
  }

  uppercase(str: string) {
    return (str) ? str.toUpperCase() : '';
  }

  pageChanged(event: PageChangedEvent) {
    this.spinner.show();
    this.searchParams.request.pageNumber = event.page;
    this.getUSers(this.searchParams);
  }

  trackByFn(index, item) {
    return index;
  }
}
