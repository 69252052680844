import { NgModule } from '@angular/core';
import { ProcessSanteRoutingModule } from './process-sante-routing.module';
import { DateAdapter, MatInputModule, MatNativeDateModule, MatTooltipModule, MatDatepickerModule } from '@angular/material';
import { MandatSignatureComponent } from './components/mandat-signature/mandat-signature.component';
import { InformationsComponent } from './components/informations/informations.component';
import { FormulesComponent } from './components/formules/formules.component';
import { BesoinsComponent } from './components/besoins/besoins.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {StepsModule} from 'primeng/steps';
import { SauvegardeDevisComponent } from './components/sauvegarde-devis/sauvegarde-devis.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PaiementComponent } from './components/paiement/paiement.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '../../assets/i18n/');
}
@NgModule({
  declarations: [
    ProfileComponent,
    BesoinsComponent,
    FormulesComponent,
    InformationsComponent,
    MandatSignatureComponent,
    SauvegardeDevisComponent,
    PaiementComponent
  ],
  imports: [
    ProcessSanteRoutingModule,
    StepsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    MatNativeDateModule,
    BsDatepickerModule.forRoot(),
    MatTooltipModule,
    MatDatepickerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})
export class ProcessSanteModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale('fr');
  }
 }
