export class RefBancaireModelDTO {
    id?: string;
    pid?: number;
    nom?: string;
    prenom?: string;
    adresseLigne1?: string;
    codePostale?: string;
    ville?: string;
    iban?: string;
    bic?: string;
    frequence?: string;
    jourPrelevement?: string;
    mode?: string;
    titulaire?: string;
    modeEnvoi?: string;
    titulairePrest?: string;
    bicPrest?: string;
    ibanPrest?: string;
    premiereCotisation?: string;

    constructor() { }
}
