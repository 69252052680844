import { EspaceProspectComponent } from './components/espace-prospect/espace-prospect.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FirstConnexionComponent } from './components/first-connexion/first-connexion.component';
import { LoginComponent } from './components/login/login.component';
import { LbpBIPComponent } from './components/lbp-bip/lbp-bip.component';
import { ResumeDevisComponent } from './components/espace-prospect/resume-devis/resume-devis.component';
import { ListDevisComponent } from './components/espace-prospect/list-devis/list-devis.component';
import { ListContratsComponent } from './components/espace-prospect/list-contrats/list-contrats.component';
import { EspaceAgentComponent } from './components/espace-agent/espace-agent.component';
import { ResumeContratComponent } from './components/espace-prospect/resume-contrat/resume-contrat.component';
import { ReinitPasswordComponent} from './components/reinit-password/reinit-password.component';
import { ExternalRedirectionComponent } from './components/external-redirection/external-redirection.component';
import { RenitMailInfoComponent } from './components/renit-mail-info/renit-mail-info.component';
import { ChangePassInfoComponent } from './components/change-pass-info/change-pass-info.component';
import { ReinitConnexionComponent } from './components/reinit-connexion/reinit-connexion.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'activation',
        component: FirstConnexionComponent
    },
    {
        path: 'reinitialisation',
        component: ReinitConnexionComponent
    },
    {
        path: 'reinitialisation-password',
        component: ReinitPasswordComponent
    },
    {
        path: 'bip',
        component: LbpBIPComponent
    },
    {
        path: 'espace-prospect',
        component: EspaceProspectComponent
    },
    {
        path: 'espace-prospect/resumeDevis',
        component: ResumeDevisComponent
    },
    {
        path: 'espace-prospect/resumeContrat',
        component: ResumeContratComponent
    },
    {
        path: 'espace-prospect/listDevis',
        component: ListDevisComponent
    },
    {
        path: 'espace-prospect/listContrats',
        component: ListContratsComponent
    },
    {
        path: 'espace-agent',
        component: EspaceAgentComponent
    },
    {
        path: 'oav-product',
        component: ExternalRedirectionComponent
    },
    {
        path: 'mail-envoyé',
        component: RenitMailInfoComponent
    },
    {
        path: 'pass-changé',
        component: ChangePassInfoComponent
    }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
