import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfig } from 'src/app/app.config';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { UserDTO } from '../../models/userDTO';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-change-pass-info',
  templateUrl: './change-pass-info.component.html',
  styleUrls: ['./change-pass-info.component.css']
})
export class ChangePassInfoComponent implements OnInit {
  isAgent = false;
  isClient = false;
  clientData;
  agentData;

  constructor(private router: Router, private cookies: CookieService, private userService: UserService,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.isAgent = this.cookies.get('user') === 'false';
    this.isClient = this.cookies.get('user') === 'true';
    if (!this.isClient && !this.isAgent) {
      this.router.navigate([OavConstants.LOGIN_URL]);
    }
    if (this.cookies.get('client') && JSON.parse(this.cookies.get('client')) != null) {
      this.clientData = JSON.parse(this.cookies.get('client'));
      if (this.clientData.clientUid) {
        this.getUserDetailsById();
      }
    }
    if (this.cookies.get('agent') && JSON.parse(this.cookies.get('agent')) != null) {
      this.agentData = JSON.parse(this.cookies.get('agent'));
    }
  }

  private getUserDetailsById() {
    this.userService.getUserDetailsById(this.clientData.clientUid).subscribe((user: UserDTO) => {
      this.clientData.nom = user.firstName;
      this.clientData.prenom = user.lastName;
      this.clientData.clientUid = user.activationId;
      console.log("clientData",this.clientData);
      this.cookies.set('client', JSON.stringify(this.clientData), undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    }, error => {
      this.spinner.hide();
      console.error('error when get user details by id');
    });
  }

  userSpace() {
    if (this.isAgent) {
      this.router.navigate([OavConstants.ESPACE_AGENT]);
    }
    if (this.isClient) {
      this.router.navigate([OavConstants.ESPACE_PROSPECT]);
    }
  }

}
