import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DevisService } from 'src/app/features/process-sante/services/devis.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormulesService } from 'src/app/features/process-sante/services/formules.service';
import { BeneficiareService } from 'src/app/features/process-sante/services/beneficiare.service';
import { FilesDTO } from 'src/app/features/process-sante/models/filesDTO';
import * as $ from 'jquery';
import { DevisModelDTO } from 'src/app/features/process-sante/models/devisDTO';
import { Beneficiaire } from 'src/app/features/process-sante/models/beneficiaire';

import { Conjoint } from 'src/app/features/process-sante/models/conjoint';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { DocumentStatiqueModel } from 'src/app/shared/models/documentStatiqueModel';
import { CookieService } from 'ngx-cookie-service';
import { DocumentStatiqueService } from 'src/app/shared/services/document-statique.service';
import { SouscripteurModelDTO } from '../../models/souscripteurModelDTO';
import { VilleService } from '../../services/ville.service';
import { Enfant } from '../../models/enfant';
import { PaysService } from '../../services/pays.service';
import { AssureurService } from '../../services/assureur.service';
import { SouscripteurService } from '../../services/souscripteur.service';
import { DevisStatus } from '../../models/devisStatus';
import { FileBeneficiareDTO } from '../../models/fileBeneficiareDTO';
import { UserUI } from '../../models/user';
import { OavDateService } from 'src/app/shared/services/oav-date.service';
import { EspaceProspectService } from 'src/app/core/services/espace-prospect.service';
import { FilterService } from '../../services/filter.service';
import { FilterRequestList } from 'src/app/models/filterRequestList';
import { FilterResponse } from 'src/app/models/filterResponse';
import { UserService } from 'src/app/core/services/user.service';
import { Person } from 'src/app/models/person';
import * as moment from 'moment';
import { GuardService } from 'src/app/shared/services/guard.service';
import { AppConfig } from 'src/app/app.config';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { SanticlaireService } from 'src/app/shared/services/santiclaire.service';
import { OavUrlConstants } from 'src/app/shared/data/OavURLConstants';

@Component({
  selector: 'app-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.css']
})
export class InformationsComponent implements OnInit {
  userUI = new UserUI();
  conjointUI = new Conjoint();
  showConjoint = false;
  showPopUp = false;
  tryToSubmitForm = false;
  validModePayment = false;
  devisId;
  focused = false;
  showPRLMens = false;
  regimeUser;
  regimeConjoint;
  enfantList = new Array<Enfant>();
  showPRLUnq = false;
  dateFin = [];
  formules = [];
  attVitaleSizeError = false;
  attVitaletypeError = false;
  isChangeAttVitale = false;
  attestationVitale: File;
  attestationVitaleFileName = OavConstants.NULL_STRING;
  attVitaleConjSizeError = false;
  attVitaleConjtypeError = false;
  isChangeAttVitaleConj = false;
  attestationVitaleConj: File;
  attestationVitaleConjFileName = OavConstants.NULL_STRING;
  validVit = true;
  fileRIB: File;
  isChangefileCarteID = false;
  fileCarteID: File;
  focusedc = false;
  maxSize = 2000000;
  showErrorFormat = false;
  dateTouched = false;
  loadPage = false;
  docsOpen = false;
  villesSouscripteur;
  villesAssure: any = [];
  villesNaissanceAssure;
  villesResiliation;
  villesConj;
  villesEnf = [];
  showEcheanceMsg = false;
  dataLoaded = false;
  agentCRC = false;
  listDocumentStatique: DocumentStatiqueModel[] = [];
  items = [
    {
      label: 'Mes informations'
    },
    {
      label: 'Mandat'
    },
    {
      label: 'Signature'
    },
    {
      label: 'Votre contrat'
    }
  ];
  activeIndex = 0;
  regimes;
  isAssure = false;
  souscripteur = new SouscripteurModelDTO();
  devis: DevisModelDTO;
  paysList;
  bsConfig: Partial<BsDatepickerConfig>;
  goMandat = false;
  parcours;
  previous;
  filesDTO = new FilesDTO();
  showDocs;
  resiliation;
  cpNotExist;
  showAssureur = false;
  cpFocusOut = false;
  nssFocusOut = false;
  cleSecFocusOut = false;
  telSouscripteurFocusOut = false;
  telAssureFocusout = false;
  cpAssureurFocuout = false;
  cpConjointFocusout = false;
  noSSConjointFocusout = false;
  cleSecConjFocusout = false;
  cpSouscripteurFocuout = false;
  cpCjtNotExist;
  cpAssNotExist;
  cpUsrNotExist;
  cpSousNotExist;
  showAssureTelMsg = false;
  showSouscripteurTelMsg = false;
  isFakeEmail = false;
  token;
  constructor(private spinner: NgxSpinnerService, private devisService: DevisService,
    private cookies: CookieService, private documentStatiqueService: DocumentStatiqueService,
    private router: Router, private beneficiareService: BeneficiareService, private filterService: FilterService,
    private villeService: VilleService, private paysService: PaysService, private localeService: BsLocaleService,
    private dateService: OavDateService, private espaceProspectService: EspaceProspectService,
    private souscripteurService: SouscripteurService,
    private userService: UserService, private guardService: GuardService,
    private santiclaireService: SanticlaireService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.spinner.show();
    this.initDataByToken();
    this.parcours = this.cookies.get(OavConstants.PARCOURS);
    this.previous = this.cookies.get('previous');
    if (this.cookies.get('agent')) {
      const agent = JSON.parse(this.cookies.get('agent'));
      this.agentCRC = agent.profil === '53';
    }
    window.scroll(0, 0);
    // this.devisId = this.cookies.get(OavConstants.DEVIS);
    frLocale.invalidDate = '';
    defineLocale('fr', frLocale);
    this.localeService.use('fr');
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', isAnimated: true, showWeekNumbers: false });
  }

  initDataByToken(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.token = params.token;
      if (this.token != null) {
        this.cookies.deleteAll();
        this.cookies.set('accessToken', this.token, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.INFORMATION_SANTE_URL]);
      } else {
        this.token = this.cookies.get('accessToken');
      }
        this.devisService.getDevisByUser().subscribe((data: any) => {
          if (this.cookies.get('devis')){
            this.devisId = this.cookies.get('devis');
          }else{
            this.cookies.set('devis', data.idDevis);
            this.devisId = data.idDevis;
          }
          
          
          this.initializeData();
          this.getListDocumentStatique();
        }, error => {
          this.cookies.delete('accessToken');
        });
    });
  }

  // checkTokenValidity() {
  //   this.activatedRoute.queryParams.subscribe(params => {
  //     this.token = params.token;
  //     this.refreshToken = params.rf;
  //     this.expire_in = params.exp;
  //     if (this.token != null) {
  //       this.cookies.set('accessToken', this.token, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
  //       this.userService.getConnectedUser().subscribe(data => {
  //         this.decryptedToken = data;
  //         this.cookies.set('_ext', '_010');
  //         this.redirectUserSwitchRole(this.decryptedToken);
  //       }, error => {
  //         this.cookies.delete('accessToken');
  //         this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
  //       });
  //     }
  //   });
  // }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => { });
  }

  getPays() {
    this.paysService.getall().subscribe(data => {
      this.paysList = data;
    }, error => {
      console.error('error when get pays list');
    });
  }

  getSouscripteurVilles(codePostal) {
    if (codePostal.length === 5) {
      this.villeService.getByCodePostal(codePostal).subscribe(data => {
        this.villesSouscripteur = data;
        this.souscripteur.adressePostale.ville = this.villesSouscripteur.length === 1 ? this.villesSouscripteur[0].libelle : '';
        this.cpSousNotExist = this.villesSouscripteur.length === 0;
      }, error => {
        this.souscripteur.adressePostale.ville = '';
        console.error('error while get villes souscripteur');
        this.cpSousNotExist = true;
      });
    } else {
      this.souscripteur.adressePostale.ville = '';
      this.cpSousNotExist = true;
    }
  }

  getAssureVillesNaissance(codePostal, villeDeNaissance) {
    if (this.userUI.lieuNaissance.pays === 'FR' && this.validCodePostalNaissance(codePostal, this.userUI.lieuNaissance.pays)
      && codePostal && codePostal.length === 5) {
      this.villeService.getByCodePostalNaissance(codePostal).subscribe(data => {
        this.villesNaissanceAssure = data;
        this.userUI.lieuNaissance.ville = this.villesNaissanceAssure.length === 1 ? this.villesNaissanceAssure[0].libelle : this.villesNaissanceAssure.length >1 ?villeDeNaissance :  '';     
        this.cpUsrNotExist = this.villesNaissanceAssure.length === 0;
      }, error => {
        this.userUI.lieuNaissance.ville = '';
        console.error('error while get villes souscripteur');
        this.cpUsrNotExist = true;
      });
    } else {
      this.userUI.lieuNaissance.ville = '';
      this.cpUsrNotExist = true;
    }
  }

  getResiliationVilles(codePostal) {
    if (codePostal.length === 5 && this.validNumeric(codePostal)) {
      this.villeService.getByCodePostal(codePostal).subscribe(data => {
        this.villesResiliation = data;
        this.userUI.assureur.adressePostale.ville = this.villesResiliation.length === 1 ? this.villesResiliation[0].libelle : '';
        this.cpNotExist = this.villesResiliation.length === 0;
      }, error => {
        this.userUI.assureur.adressePostale.ville = '';
        this.cpNotExist = true;
        console.error('error while get villes pour resiliation');
      });
    } else {
      this.userUI.assureur.adressePostale.ville = '';
      this.villesResiliation = [];
    }
  }

  getConjointVillesNaissance(codePostal, pays,villeNaissance) {
    if (pays === 'FR' && codePostal.length === 5) {
      this.villeService.getByCodePostalNaissance(codePostal).subscribe(data => {
        this.villesConj = data;
        this.conjointUI.ville = this.villesConj.length === 1 ? this.villesConj[0].libelle : this.villesConj.length > 1 ? villeNaissance : '';
        this.cpCjtNotExist = this.villesConj.length === 0;
      }, error => {
        this.conjointUI.ville = '';
        this.cpCjtNotExist = true;
        console.error('error while get villes souscripteur');
      });
    } else {
      this.conjointUI.ville = '';
      this.cpCjtNotExist = true;
      this.villesConj = [];
    }
  }

  getEnfantVillesNaissance(codePostal, pays, index,villeNaissance) {
    if (pays === 'FR' && codePostal.length === 5) {
      this.villeService.getByCodePostalNaissance(codePostal).subscribe(data => {
        this.villesEnf[index] = data; 
        this.enfantList[index].ville = this.villesEnf[index].length === 1 ? this.villesEnf[index][0].libelle : this.villesEnf[index].length > 1 ? villeNaissance :'';
        this.enfantList[index].cpEnfNotExist = this.villesEnf[index].length === 0;
      }, error => {
        this.enfantList[index].ville = '';
        this.enfantList[index].cpEnfNotExist = true;
        console.error('error while get villes of child');
      });
    } else {
      this.villesEnf[index] = [];
      this.enfantList[index].ville = '';
      this.enfantList[index].cpEnfNotExist = true;
    }
  }

  getProduit() {
    if (this.cookies.get('produit')) {
      if (this.cookies.get('produit') === OavConstants.PRODUIT_SANTE) {
        return 'Sante';
      } else if (this.cookies.get('produit') === OavConstants.PRODUIT_ACDS) {
        return 'ACDS';
      }
    } else {
      return null;
    }
  }


  getParcours() {
    if (this.parcours === 'MIXTE') {
      return 'Prospect';
    } else if (this.parcours === 'AGENT') {
      return 'Agent';
    } else {
      return null;
    }
  }

  validNumeric(num) {
    const re = /^[0-9]\d*$/;
    return re.test(String(num));
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: 'application/pdf' });
  }

  openDocumentStatique(doc) {
    this.documentStatiqueService.getDocumentStatique(doc).subscribe(rep => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(this.base64ToBlob(rep.encoded));
      } else {
        const fileURL = URL.createObjectURL(this.base64ToBlob(rep.encoded));
        if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = doc;
          a.click();
          return;
        }
        window.open(fileURL);
      }
    });
  }

  getListDocumentStatique() {
    this.documentStatiqueService.getListDocumentsByProduitAndParcoursAndEcran(this.getProduit(),
      this.getParcours(), 'Mes informations').subscribe(rep => {
        this.listDocumentStatique = rep;
        if (this.listDocumentStatique.length > 0) {
          this.showDocs = true;
        }
      });
  }


  initializeData() {
    this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
      this.devis = devis;
      this.isFakeEmail = devis.user.fakeEmail;
      this.isAssure = this.devis.assure;
      // if (this.previous === 'formule' && this.cookies.get('user')) {
      //   this.initializeFromDB(this.devis);
      // } else {
        this.initData(this.devis);
      // }
      if (this.isAssure) {
        this.userUI.adresseLigne1 = this.devis.souscripteur.adressePostale.adresseLigne1;
        this.userUI.complement = this.devis.souscripteur.adressePostale.complement;
        this.userUI.lieuDit = this.devis.souscripteur.adressePostale.lieuDit;
        this.userUI.postalCodes = this.devis.souscripteur.adressePostale.codePostale;
      }
      this.souscripteur = this.devis.souscripteur;
      if (this.souscripteur.adressePostale && this.souscripteur.adressePostale.codePostale && !this.souscripteur.adressePostale.ville) {
        this.getSouscripteurVilles(this.souscripteur.adressePostale.codePostale);
      }
      this.getPays();
      this.dataLoaded = true;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  private initializeFromDB(devis) {
    this.villeService.getByCodePostal(devis.souscripteur.adressePostale.codePostale).subscribe(data => {
      this.villesAssure = data;
      if (this.villesAssure.length > 0) {
        this.userUI.ville = this.villesAssure[0].libelle;
      }
    }, error => {
      console.error('error when get assure villes');
    });
    for (const benef of devis.beneficiaires) {
      const fileBenef = new FileBeneficiareDTO();
      fileBenef.idBenef = benef.id;
      this.filesDTO.files.push(fileBenef);
      if (benef.typeBenef === 1) {
        this.initPrincipalUser(benef, devis);
        this.beneficiareService.getDevisDocument(benef.id, devis.idDevis).subscribe(res => {
          const file: any = res;
          if (file) {
            this.attestationVitaleFileName = file.name;
            this.isChangeAttVitale = true;
          }
        }, error => {
          console.error('error while loading documents');
        });
      }
      if (benef.typeBenef === 2) {
        this.showConjoint = true;
        this.initConjoint(benef);
        this.beneficiareService.getDevisDocument(benef.id, devis.idDevis).subscribe(res => {
          const file: any = res;
          if (file) {
            this.attestationVitaleConjFileName = file.name;
            this.isChangeAttVitaleConj = true;
          }
        }, error => {
          console.error('error while loading documents');
        });
      }
      if (benef.typeBenef === 3) {
        this.initChild(benef, devis.idDevis);
      }
    }
    if (devis.assure) {
      this.getBeneficiairesByUser(devis);
    }
  }

  private initData(devis) {
    this.enfantList = new Array<Enfant>();
    this.villeService.getByCodePostal(devis.souscripteur.adressePostale.codePostale).subscribe(data => {
      this.villesAssure = data;
      this.userUI.ville = this.villesAssure.length === 1 ? this.villesAssure[0].libelle : devis.souscripteur.adressePostale.ville;
    }, error => {
      this.userUI.ville = '';
      console.error('error when get assure villes');
    });
    for (const benef of devis.beneficiaires) {
      const fileBenef = new FileBeneficiareDTO();
      fileBenef.idBenef = benef.id;
      this.filesDTO.files.push(fileBenef);
      if (benef.typeBenef === 1) {
        this.initPrincipalUser(benef, devis);
        this.beneficiareService.getDevisDocument(benef.id, devis.idDevis).subscribe(res => {
          const file: any = res;
          if (file) {
            this.attestationVitaleFileName = file.name;
            this.isChangeAttVitale = true;
          }
        }, error => {
          console.error('error while loading documents');
        });
      }
      if (benef.typeBenef === 2) {
        this.showConjoint = true;
        this.initConjoint(benef);
        this.beneficiareService.getDevisDocument(benef.id, devis.idDevis).subscribe(res => {
          const file: any = res;
          if (file) {
            this.attestationVitaleConjFileName = file.name;
            this.isChangeAttVitaleConj = true;
          }
        }, error => {
          console.error('error while loading documents');
        });
      }
      if (benef.typeBenef === 3) {
        this.initChild(benef, devis.idDevis);
      }
    }
    // if (devis.assure) {
    //   this.getBeneficiairesByUser(devis);
    // }
  }

  private initChild(element, idDevis) {
    const enfant = new Enfant();
    this.beneficiareService.getDevisDocument(element.id, idDevis).subscribe(res => {
      const file: any = res;
      if (file) {
        enfant.attestationVitale.fileName = file.name;
        enfant.attestationVitale.isChange = true;
      }
    }, error => {
      console.error('error while loading documents');
    });
    enfant.nom = (!element.nomBenef ? OavConstants.NULL_STRING : element.nomBenef);
    enfant.prenom = (!element.prenomBenef ? OavConstants.NULL_STRING : element.prenomBenef);
    enfant.sexe = element.sexe;
    enfant.noSS = (!element.numSS ? OavConstants.NULL_STRING : element.numSS);
    enfant.cleSec = (!element.cleSS ? OavConstants.NULL_STRING : element.cleSS);
    enfant.codePostal = (!element.codePostaleNaissance ? OavConstants.NULL_STRING : element.codePostaleNaissance);
    enfant.paysNaissance = (!element.paysNaissance ? 'FR' : element.paysNaissance);
    enfant.idBenef = element.id;
    enfant.dateNaissance = (!element.dateNaissance ? null : element.dateNaissance);
    if (enfant.codePostal && element.paysNaissance === 'FR') {
      this.getEnfantVillesNaissance(enfant.codePostal, element.paysNaissance, this.enfantList.length,element.villeNaissance);
    } else {
      enfant.ville = element.villeNaissance;
    }
    this.enfantList.push(enfant);
  }

  private initConjoint(element) {
    this.conjointUI = new Conjoint();
    this.conjointUI.idBenef = element.id;
    this.conjointUI.nom = (!element.nomBenef ? OavConstants.NULL_STRING : element.nomBenef);
    this.conjointUI.prenom = (!element.prenomBenef ? OavConstants.NULL_STRING : element.prenomBenef);
    this.conjointUI.nomJeuneFille = (!element.nomJeuneFille ? OavConstants.NULL_STRING : element.nomJeuneFille);
    this.conjointUI.sexe = element.sexe;
    this.conjointUI.noSS = (!element.numSS ? OavConstants.NULL_STRING : element.numSS);
    this.conjointUI.cleSec = (!element.cleSS ? OavConstants.NULL_STRING : element.cleSS);
    this.conjointUI.codePostal = (!element.codePostaleNaissance ? OavConstants.NULL_STRING : element.codePostaleNaissance);
    this.conjointUI.paysNaissance = (!element.paysNaissance ? 'FR' : element.paysNaissance);
    this.conjointUI.dateNaissance = (!element.dateNaissance ? null : element.dateNaissance);
    if (this.conjointUI.codePostal && this.conjointUI.paysNaissance === 'FR') {
      this.getConjointVillesNaissance(this.conjointUI.codePostal, this.conjointUI.paysNaissance,element.villeNaissance);
    } else {
      this.conjointUI.ville = element.villeNaissance;
    }
  }

  private initPrincipalUser(benef, devis) {
    this.resiliation = devis.resiliation;
    this.userUI.assureur.resilier = devis.resiliation;
    if (this.resiliation) {
      this.intiDateEcheance(this.resiliation, devis);
    }
    this.userUI.civilite = benef.sexe;
    this.userUI.nom = benef.nomBenef;
    this.userUI.prenom = benef.prenomBenef;
    this.userUI.nomNaissance = benef.nomJeuneFille;
    this.userUI.idBenef = benef.id;
    this.userUI.email = devis.contact.email;
    this.userUI.adresseLigne1 = (!devis.adressePostale.adresseLigne1 ? OavConstants.NULL_STRING : devis.adressePostale.adresseLigne1);
    this.userUI.postalCodes = (!devis.adressePostale.codePostale ? OavConstants.NULL_STRING : devis.adressePostale.codePostale);
    this.userUI.ville = (!devis.adressePostale.ville ? OavConstants.NULL_STRING : devis.adressePostale.ville);
    this.userUI.lieuDit = (!devis.adressePostale.lieuDit ? OavConstants.NULL_STRING : devis.adressePostale.lieuDit);
    this.userUI.complement = (!devis.adressePostale.complement ? OavConstants.NULL_STRING : devis.adressePostale.complement);
    this.userUI.noSS = (!devis.beneficiaires[0].numSS ? OavConstants.NULL_STRING : devis.beneficiaires[0].numSS);
    this.userUI.cleSec = (!devis.beneficiaires[0].cleSS ? OavConstants.NULL_STRING : devis.beneficiaires[0].cleSS);
    this.userUI.dateNaissance = devis.beneficiaires[0].dateNaissance;
    this.userUI.modePaiement = devis.modePaiement;
    if (devis.assure) {
      this.userUI.telephone = !devis.souscripteur.contact.telMobile ? OavConstants.NULL_STRING : devis.souscripteur.contact.telMobile;
    } else {
      this.userUI.telephone = !devis.contact.telMobile ? OavConstants.NULL_STRING : devis.contact.telMobile;
    }
    this.userUI.lieuNaissance.pays = (!devis.beneficiaires[0].paysNaissance ? 'FR' : devis.beneficiaires[0].paysNaissance);
    this.userUI.lieuNaissance.codePostal = (!devis.beneficiaires[0].codePostaleNaissance ?
      OavConstants.NULL_STRING : devis.beneficiaires[0].codePostaleNaissance);
    if (this.userUI.lieuNaissance.codePostal && this.userUI.lieuNaissance.pays === 'FR') {
      this.getAssureVillesNaissance(this.userUI.lieuNaissance.codePostal,benef.villeNaissance);
    } else {
      this.userUI.lieuNaissance.ville = devis.beneficiaires[0].villeNaissance;
    }
    if (devis.assureur) {
      this.userUI.assureur = devis.assureur;
      if (this.userUI.assureur.dateEcheance != null) {
        this.userUI.assureur.dateEcheance = this.dateService.loadUTCDate(devis.assureur.dateEcheance);
      }
      if (this.userUI.assureur.adressePostale.codePostale) {
        this.getResiliationVilles(this.userUI.assureur.adressePostale.codePostale);
      }
    }
  }

  backViewTab(enfantList, userUI, conjointUI, souscripteur) {
    this.conjointUI = conjointUI;
    this.enfantList = enfantList;
    this.userUI = userUI;
    this.souscripteur = souscripteur;
    this.goMandat = false;
    const statusDevis = DevisStatus.INFORMATION;
    if (this.verifFormDataForSauvegarde()) {
      this.updateBenificiaire(enfantList, userUI, conjointUI, statusDevis);
      this.uploadFiles(enfantList, conjointUI);
    }
  }

  verifFormDataForSauvegarde() {
    return this.validSouscripteurInfoForSauvegarde(this.souscripteur) && this.validAdherentInfoForSauvegarde(this.userUI)
      && this.validConjointInfoForSauvegarde(this.conjointUI) && this.validChildrenDataForSauvegarde() &&
      this.validAssureurForSauvegarde(this.userUI.assureur);
  }

  private validConjointInfoForSauvegarde(conjointUI) {
    if (this.showConjoint) {
      return ((!conjointUI.prenom || this.validField(conjointUI.prenom))
        && (!conjointUI.nom || this.validField(conjointUI.nom)) && (!(conjointUI.paysNaissance && conjointUI.codePostal) ||
          this.validCodePostalNaissance(conjointUI.codePostal, conjointUI.paysNaissance)) &&
        (!(conjointUI.noSS && conjointUI.cleSec) || this.validKeyRespC(conjointUI)) &&
        (!conjointUI.cleSec || this.validCleSec(conjointUI.cleSec)) && (!conjointUI.noSS ||
          this.verifNumSecSociale(conjointUI.noSS, conjointUI.dateNaissance.toString(), conjointUI.sexe)) && (
          (this.isChangeAttVitaleConj && !this.attVitaleConjSizeError && !this.attVitaleConjtypeError) || !this.isChangeAttVitaleConj));
    } else {
      return true;
    }
  }
  private validSouscripteurInfoForSauvegarde(souscripteur) {
    if (!this.isAssure) {
      return ((!souscripteur.nom || this.validField(souscripteur.nom)) && (!souscripteur.prenom || this.validField(souscripteur.prenom)) &&
        (!souscripteur.adressePostale.codePostale || this.validCodePostal(souscripteur.adressePostale.codePostale)) &&
        (!souscripteur.contact.telMobile || this.validPhoneNumber(souscripteur.contact.telMobile)) &&
        (!this.userUI.email || this.validEmail(this.userUI.email)));
    }
    return true;
  }

  private validAssureurForSauvegarde(assureur) {
    if (this.userUI.assureur.resilier) {
      return (!assureur.adressePostale.codePostale || this.validCodePostal(assureur.adressePostale.codePostale));
    }
    return true;
  }

  private validAdherentInfoForSauvegarde(userUI) {
    return (!userUI.prenom || this.validField(userUI.prenom)) && (!userUI.nom || this.validField(userUI.nom)) &&
      (!userUI.postalCodes || this.validCodePostal(userUI.postalCodes)) &&
      (!this.userUI.telephone || this.validPhoneNumber(this.userUI.telephone)) &&
      (!userUI.noSS || this.verifNumSecSociale(userUI.noSS, userUI.dateNaissance.toString(), userUI.civilite)) &&
      (!(userUI.lieuNaissance.codePostal && userUI.lieuNaissance.pays) ||
        this.validCodePostalNaissance(userUI.lieuNaissance.codePostal, userUI.lieuNaissance.pays))
      && (!(userUI.noSS && userUI.cleSec) || this.validKeyRespC(userUI)) &&
      (!userUI.cleSec || this.validCleSec(userUI.cleSec))
      && ((this.isChangeAttVitale && !this.attVitaleSizeError && !this.attVitaletypeError)
        || !this.isChangeAttVitale);
  }

  private validChildrenDataForSauvegarde() {
    let valid = true;
    if (this.enfantList.length > 0) {
      let i = 0;
      this.enfantList.forEach((enfantDet) => {
        valid = valid && (this.validChildForSauvegarde(enfantDet));
        i++;
      });
    }
    return valid;
  }
  private validChildForSauvegarde(enfantDet) {
    return ((!enfantDet.prenom || this.validField(enfantDet.prenom)) && (!enfantDet.nom || this.validField(enfantDet.nom))
      && (!(enfantDet.paysNaissance && enfantDet.codePostal) ||
        this.validCodePostalNaissance(enfantDet.codePostal, enfantDet.paysNaissance)) &&
      (!enfantDet.ville || this.testForBlackSpaces(enfantDet.ville)) &&
      (!(enfantDet.cleSec && enfantDet.noSS) || this.validKeyRespC(enfantDet)) &&
      (!enfantDet.cleSec || this.validCleSec(enfantDet.cleSec)) && (!enfantDet.noSS || this.validNumSecSociale(enfantDet.noSS)) &&
      ((enfantDet.attestationVitale.isChange && !enfantDet.attestationVitale.sizeError && !enfantDet.attestationVitale.typeError)
        || !enfantDet.attestationVitale.isChange));
  }

  changeViewTab(enfantList, userUI, conjointUI, souscripteur) {
    this.conjointUI = conjointUI;
    this.enfantList = enfantList;
    this.userUI = userUI;
    this.souscripteur = souscripteur;
    this.tryToSubmitForm = true;
    this.showAssureur = true;
    const filterRequestList = new FilterRequestList();
    this.prepareFilterRequestList(filterRequestList);
    this.filterUsers(filterRequestList);
  }

  private prepareFilterRequestList(filterRequestList: FilterRequestList) {
    if (this.cookies.get('agent')) {
      filterRequestList.userId = JSON.parse(this.cookies.get('agent')).idRHAgent;
    }
    filterRequestList.person.push(this.createPersonFromAdherent(this.userUI));
    if (this.showConjoint) {
      filterRequestList.person.push(this.createPersonFromConjoint(this.conjointUI));
    }
    if (this.enfantList && this.enfantList.length > 0) {
      this.enfantList.forEach(child => {
        filterRequestList.person.push(this.createPersonFromChild(child));
      });
    }
  }

  private createPersonFromAdherent(userUI: UserUI) {
    const person = new Person();
    person.address.addressSupplement = userUI.complement;
    person.address.city.name = userUI.ville;
    person.address.city.zipCode = userUI.postalCodes;
    person.address.country.code = 'FR';
    person.address.country.name = 'France';
    person.address.place = userUI.lieuDit;
    person.birthCity = userUI.lieuNaissance.ville;
    person.birthDepartmentCode = userUI.lieuNaissance.pays;
    person.birthDate = userUI.dateNaissance;
    person.firstName = userUI.prenom;
    person.lastName = userUI.nom;
    person.gender = userUI.civilite ? 'F' : 'M';
    person.idPersonPartner = (this.souscripteur.id.toString().substring(0, 10)
      + userUI.nom.replace(/\s/g, '').substring(0, 10) + userUI.prenom.replace(/\s/g, '').substring(0, 20) +
      userUI.dateNaissance.toString().substring(0, 10)).toUpperCase();
    person.surname = userUI.nomNaissance ? userUI.nomNaissance : userUI.nom;
    return person;
  }

  private createPersonFromChild(child: Enfant) {
    const person = new Person();
    person.address.country.code = 'FR';
    person.address.country.name = 'France';
    person.birthCity = child.ville;
    person.birthCountryCode = child.paysNaissance;
    person.birthDate = child.dateNaissance + '';
    person.firstName = child.prenom;
    person.lastName = child.nom;
    person.gender = child.sexe ? 'F' : 'M';
    person.idPersonPartner = (this.souscripteur.id.toString().substring(0, 10)
      + child.nom.replace(/\s/g, '').substring(0, 10) + child.prenom.replace(/\s/g, '').substring(0, 20) +
      child.dateNaissance.toString().substring(0, 10)).toUpperCase();
    person.surname = child.nom;
    return person;
  }

  private createPersonFromConjoint(conjointUI: Conjoint) {
    const person = new Person();
    person.address.country.code = 'FR';
    person.address.country.name = 'France';
    person.birthCity = conjointUI.ville;
    person.birthCountryCode = conjointUI.paysNaissance;
    person.birthDate = conjointUI.dateNaissance + '';
    person.firstName = conjointUI.prenom;
    person.lastName = conjointUI.nom;
    person.gender = conjointUI.sexe ? 'F' : 'M';
    person.idPersonPartner = (this.souscripteur.id.toString().substring(0, 10)
      + conjointUI.nom.replace(/\s/g, '').substring(0, 10) + conjointUI.prenom.replace(/\s/g, '').substring(0, 20) +
      conjointUI.dateNaissance.toString().substring(0, 10)).toUpperCase();
    person.surname = conjointUI.nomJeuneFille ? conjointUI.nomJeuneFille : conjointUI.nom;
    return person;
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  private verifFormData() {
    return this.validSouscripteurInfo(this.souscripteur) && this.validAdherentInfo(this.userUI)
      && this.validConjointInfo(this.conjointUI) && this.validChildrenData() && this.validAssureur(this.userUI.assureur);
  }

  private validAssureur(assureur) {
    if (this.userUI.assureur.resilier) {
      return assureur.mutuelle && this.testForBlackSpaces(assureur.mutuelle) &&
        assureur.dateEcheance && this.controlDate(assureur.dateEcheance) &&
        assureur.adressePostale.adresseLigne1 && this.testForBlackSpaces(assureur.adressePostale.adresseLigne1) &&
        assureur.adressePostale.ville && this.validCodePostal(assureur.adressePostale.codePostale) && assureur.resilier &&
        assureur.contrat && this.testForBlackSpaces(assureur.contrat);
    }
    return true;
  }

  private validSouscripteurInfo(souscripteur) {
    if (!this.isAssure) {
      return (this.validField(souscripteur.nom) && this.validField(souscripteur.prenom) &&
        souscripteur.adressePostale.adresseLigne1 && this.testForBlackSpaces(souscripteur.adressePostale.adresseLigne1)
        && this.validCodePostal(souscripteur.adressePostale.codePostale) && this.validMobileNumber(souscripteur.contact.telMobile) &&
        souscripteur.adressePostale.ville && this.validPhoneNumber(souscripteur.contact.telMobile) &&
        (this.userUI.email && this.validEmail(this.userUI.email)));
    }
    return true;
  }

  private validAdherentInfo(userUI) {
    return this.validField(userUI.prenom) && this.validOuiNonRadio(userUI.assureur.resilier) &&
      this.validField(userUI.nom) && userUI.adresseLigne1 && this.testForBlackSpaces(userUI.adresseLigne1) &&
      userUI.ville && this.validCodePostal(userUI.postalCodes) && this.validPhoneNumber(this.userUI.telephone) &&
      (this.validMobileNumber(this.userUI.telephone) || !this.isAssure) && userUI.lieuNaissance.pays && userUI.lieuNaissance.ville &&
      this.testForBlackSpaces(userUI.lieuNaissance.ville) &&
      this.verifNumSecSociale(userUI.noSS, userUI.dateNaissance.toString(), userUI.civilite) &&
      this.validCodePostalNaissance(userUI.lieuNaissance.codePostal, userUI.lieuNaissance.pays)
      && this.validKeyRespC(userUI) && this.validCleSec(userUI.cleSec) &&
      ((this.isChangeAttVitale && !this.attVitaleSizeError && !this.attVitaletypeError)
        || !this.isChangeAttVitale);
  }

  validConjointInfo(conjointUI) {
    if (this.showConjoint) {
      return (this.validOuiNonRadio(conjointUI.sexe) && this.validField(conjointUI.prenom) && this.validField(conjointUI.nom)
        && conjointUI.paysNaissance && this.validCodePostalNaissance(conjointUI.codePostal, conjointUI.paysNaissance) && conjointUI.ville
        && this.testForBlackSpaces(conjointUI.ville) &&
        this.validKeyRespC(conjointUI) && this.validCleSec(conjointUI.cleSec) &&
        this.verifNumSecSociale(conjointUI.noSS, conjointUI.dateNaissance.toString(), conjointUI.sexe) && (
          (this.isChangeAttVitaleConj && !this.attVitaleConjSizeError && !this.attVitaleConjtypeError) || !this.isChangeAttVitaleConj));
    } else {
      return true;
    }
  }

  private validField(field) {
    const re = /^[_'.@A-zÀ-ÿ- ]*$/;
    return (field && field.length > 0 && re.test(String(field)) && this.testForBlackSpaces(field.toString()));
  }

  private validCodePostal(code) {
    const re = /^\d*/;
    return code && re.test(String(code)) && code.length > 4;
  }

  private validCodePostalNaissance(code, pays) {
    const re = /^\d*/;
    if (pays === 'FR' && code) {
      return re.test(String(code)) && code.length > 4 && this.testForBlackSpaces(code);
    } else {
      return this.testForBlackSpaces(code);
    }
  }

  private validPhoneNumber(tel) {
    const re = /^\d+$/;
    return (this.verifMobilePattern(tel) && tel && tel.length === 10 && re.test(String(tel)));
  }

  private validEmail(email) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validNumSecSociale(nss: string) {
    const re = /[0-9]{13}/;
    return nss && nss.length === 13 && (re.test(String(nss)) || nss.substring(5, 7) === '2A' || nss.substring(5, 7) === '2B');
  }

  verifNumSecSociale(nss: string, dateNaissance: string, sexe: boolean): boolean {
    const date = dateNaissance.toString().substring(0, 10);
    const gender = sexe ? '2' : '1';
    // return nss.substring(0, 1) === gender && nss.substring(1, 3) === date.substring(2, 4) &&
    //   nss.substring(3, 5) === date.substring(5, 7) && this.validNumSecSociale(nss);
    return this.validNumSecSociale(nss);
  }

  validCleSec(cle) {
    const re = /[0-9]{2}/;
    return cle && re.test(String(cle));
  }

  validOuiNonRadio(r) {
    if (r !== null && r !== undefined) {
      return (r.toString() === 'true' || r.toString() === 'false');
    }
    return false;
  }

  private validChildrenData() {
    let valid = true;
    if (this.enfantList.length > 0) {
      let i = 0;
      this.enfantList.forEach((enfantDet) => {
        valid = valid && (this.validChild(enfantDet));
        i++;
      });
    }
    return valid;
  }
  private validChild(enfantDet) {
    return (this.validOuiNonRadio(enfantDet.sexe) && this.validField(enfantDet.prenom) && this.validField(enfantDet.nom)
      && enfantDet.paysNaissance && this.validCodePostalNaissance(enfantDet.codePostal, enfantDet.paysNaissance) && enfantDet.ville &&
      this.testForBlackSpaces(enfantDet.ville) &&
      this.validKeyRespC(enfantDet) && this.validCleSec(enfantDet.cleSec) && this.validNumSecSociale(enfantDet.noSS) && (
        (enfantDet.attestationVitale.isChange && !enfantDet.attestationVitale.sizeError && !enfantDet.attestationVitale.typeError)
        || !enfantDet.attestationVitale.isChange));
  }

  verifMobile(tel) {
    return tel.substring(0, 2) === OavConstants.VALID_PHONE_NUMBER_06 || tel.substring(0, 2) === OavConstants.VALID_PHONE_NUMBER_07;
  }

  verifMobilePattern(tel) {
    const re = /^[0-9]*$/;
    return re.test(tel);
  }

  showMessage(numAdherent) {
    this.showPopUp = numAdherent == null && numAdherent === OavConstants.NULL_STRING;
  }

  validKeyRespC(data) {
    let numSS = data.noSS.replace('2B', '18');
    numSS = numSS.replace('2A', '19');
    const KeyCalculated = 97 - (numSS % 97);
    let key;
    if (String(data.cleSec).length === 2 && String(data.cleSec).substring(0, 1) === '0') {
      key = String(data.cleSec).substring(1, 2);
    } else {
      key = String(data.cleSec);
    }
    return String(key) === String(KeyCalculated);
  }


  changeAttestationVitale(event) {
    this.isChangeAttVitale = true;
    this.attestationVitale = event.target.files[0];
    this.attestationVitaleFileName = this.attestationVitale.name;
    if (this.attestationVitale.type === OavConstants.IMAGE_JPEG || this.attestationVitale.type === OavConstants.APPLICATION_PDF
      || this.attestationVitale.type === OavConstants.IMAGE_PNG) {
      this.attVitaletypeError = false;
      if (this.attestationVitale.size > this.maxSize) {
        this.attVitaleSizeError = true;
      } else {
        this.attVitaleSizeError = false;
      }
    } else {
      this.attVitaletypeError = true;
    }
  }

  deleteAttestationVitale() {
    this.attVitaleSizeError = false;
    this.attVitaletypeError = false;
    this.isChangeAttVitale = false;
    this.attestationVitale = null;
    this.attestationVitaleFileName = OavConstants.NULL_STRING;
  }

  changeAttestationVitaleEnf(event, enfantList, i) {
    this.enfantList = enfantList;
    this.enfantList[i].attestationVitale.isChange = true;
    this.enfantList[i].attestationVitale.attestation = event.target.files[0];
    this.enfantList[i].attestationVitale.fileName = this.enfantList[i].attestationVitale.attestation.name;
    if (this.enfantList[i].attestationVitale.attestation.type === OavConstants.IMAGE_JPEG ||
      this.enfantList[i].attestationVitale.attestation.type === OavConstants.APPLICATION_PDF
      || this.enfantList[i].attestationVitale.attestation.type === OavConstants.IMAGE_PNG) {
      this.enfantList[i].attestationVitale.typeError = false;
      if (this.enfantList[i].attestationVitale.attestation.size > this.maxSize) {
        this.enfantList[i].attestationVitale.sizeError = true;
      } else {
        this.enfantList[i].attestationVitale.sizeError = false;
      }
    } else {
      this.enfantList[i].attestationVitale.typeError = true;
    }
  }

  deleteAttestationVitaleEnf(enfantList, i) {
    this.enfantList = enfantList;
    this.enfantList[i].attestationVitale.sizeError = false;
    this.enfantList[i].attestationVitale.typeError = false;
    this.enfantList[i].attestationVitale.isChange = false;
    this.enfantList[i].attestationVitale.attestation = null;
    this.enfantList[i].attestationVitale.fileName = OavConstants.NULL_STRING;
  }

  changeAttestationVitaleConj(event) {
    this.isChangeAttVitaleConj = true;
    this.attestationVitaleConj = event.target.files[0];
    this.attestationVitaleConjFileName = this.attestationVitaleConj.name;
    if (this.attestationVitaleConj.type === OavConstants.IMAGE_JPEG || this.attestationVitaleConj.type === OavConstants.APPLICATION_PDF
      || this.attestationVitaleConj.type === OavConstants.IMAGE_PNG) {
      this.attVitaleConjtypeError = false;
      if (this.attestationVitaleConj.size > this.maxSize) {
        this.attVitaleConjSizeError = true;
      } else {
        this.attVitaleConjSizeError = false;
      }
    } else {
      this.attVitaleConjtypeError = true;
    }
  }
  deleteAttestationVitaleConj() {
    this.attVitaleConjSizeError = false;
    this.attVitaleConjtypeError = false;
    this.isChangeAttVitaleConj = false;
    this.attestationVitaleConj = null;
    this.attestationVitaleConjFileName = OavConstants.NULL_STRING;
  }

  uploadFiles(enfantList, conjointUI) {
    if (this.attestationVitale) {
      this.filesDTO.files[0].attestation = this.attestationVitale;
    }
    if (conjointUI && this.attestationVitaleConj) {
      this.filesDTO.files[1].attestation = this.attestationVitaleConj;
    }
    let i = 0;
    for (const enf of enfantList) {
      if (this.enfantList[i].attestationVitale.attestation) {
        this.filesDTO.files[i + 2].attestation = this.enfantList[i].attestationVitale.attestation;
      }
      i++;
    }
    this.devisService.getDevisById(this.devisId).subscribe((devis: DevisModelDTO) => {
      this.filesDTO.idAdherent = devis.souscripteur.id;
      this.filesDTO.idDevis = devis.idDevis;
      this.beneficiareService.uploadFiles(this.filesDTO).subscribe(res => {
      }, error => {
        this.spinner.hide();
      });
    }, error => {
      this.spinner.hide();
    });
  }

  updateBenificiaire(enfantList, userUI, conjointUI, status) {
    this.spinner.show();
    const devisDTO = new DevisModelDTO();
    if (this.testForBlackSpaces(this.devis.idRHApporteur)) {
      devisDTO.idRHApporteur = this.devis.idRHApporteur;
    }
    this.devisService.getDevisById(this.devisId).subscribe(data => {
      const devis: any = data;
      devis.status = status;
      this.initDevisDTO(devis, devisDTO, userUI);
      devisDTO.beneficiaires.push(this.createBenificiaireFromAdherent(userUI));
      if (this.showConjoint) {
        devisDTO.beneficiaires.push(this.createBenificiaireFromConjoint(conjointUI));
      }
      if (enfantList && enfantList.length > 0) {
        enfantList.forEach(child => {
          devisDTO.beneficiaires.push(this.createBeneficiaireFromChild(child));
        });
      }
      if (userUI.assureur.resilier && (userUI.assureur == null || userUI.assureur.id == null)) {
        userUI.assureur.dateEcheance = this.dateService.saveUTCDate(userUI.assureur.dateEcheance);
        userUI.assureur.idDevis = this.devisId;
        // this.assureurService.create(userUI.assureur).subscribe(obj => {
        // const createdAssureur: any = obj;
        this.beneficiareService.updateBenificiare(devisDTO).subscribe((res: DevisModelDTO) => {
          devisDTO.assureur = userUI.assureur;
          this.devisService.updateDevisInfos(devisDTO).subscribe(result => {
            this.updateSouscripteur(this.souscripteur);
          }, error => {
            this.spinner.hide();
          });
        }, error => {
          this.spinner.hide();
        });
        // }, error => {
        //   this.spinner.hide();
        // });
      } else {
        if (userUI.assureur != null && userUI.assureur.dateEcheance != null) {
          userUI.assureur.dateEcheance = this.dateService.saveUTCDate(userUI.assureur.dateEcheance);
        }
        devisDTO.assureur = userUI.assureur;
        this.beneficiareService.updateBenificiare(devisDTO).subscribe((res: DevisModelDTO) => {
          this.devisService.updateDevisInfos(devisDTO).subscribe(result => {
            this.updateSouscripteur(this.souscripteur);
          }, error => {
            this.spinner.hide();
          });
        }, error => {
          this.spinner.hide();
        });
      }
    }, error => {
      this.spinner.hide();
    });
  }

  private initDevisDTO(devis, devisDTO: DevisModelDTO, userUI) {
    devisDTO.adressePostale = devis.adressePostale;
    devisDTO.contact = devis.contact;
    devisDTO.status = devis.status;
    devisDTO.idDevis = devis.idDevis;
    devisDTO.formule = devis.formule;
    devisDTO.dateAdhesion = devis.dateAdhesion;
    devisDTO.dateSejourFrom = devis.dateSejourFrom;
    devisDTO.dateSejourTo = devis.dateSejourTo;
    devisDTO.souscripteur = this.souscripteur;
    devisDTO.adressePostale.adresseLigne1 = userUI.adresseLigne1;
    devisDTO.adressePostale.codePostale = userUI.postalCodes;
    devisDTO.adressePostale.ville = userUI.ville;
    devisDTO.contact.telMobile = userUI.telephone;
    devisDTO.contact.email = userUI.email;
    devisDTO.modePaiement = userUI.modePaiement;
    if (this.testForBlackSpaces(userUI.complement)) {
      devisDTO.adressePostale.complement = userUI.complement;
    }
    if (this.testForBlackSpaces(userUI.lieuDit)) {
      devisDTO.adressePostale.lieuDit = userUI.lieuDit;
    }
    devisDTO.beneficiaires = new Array<Beneficiaire>();
  }

  private createBenificiaireFromConjoint(conjoint) {
    const benificiare = new Beneficiaire();
    benificiare.nomBenef = conjoint.nom;
    benificiare.prenomBenef = conjoint.prenom;
    benificiare.numSS = conjoint.noSS;
    benificiare.cleSS = conjoint.cleSec;
    benificiare.typeBenef = 2;
    benificiare.sexe = conjoint.sexe;
    if (this.testForBlackSpaces(conjoint.nomJeuneFille)) {
      benificiare.nomJeuneFille = conjoint.nomJeuneFille;
    }
    benificiare.codePostaleNaissance = conjoint.codePostal;
    benificiare.villeNaissance = conjoint.ville;
    benificiare.paysNaissance = conjoint.paysNaissance;
    return benificiare;
  }

  createBeneficiaireFromChild(child) {
    const benificiare = new Beneficiaire();
    benificiare.nomBenef = child.nom;
    benificiare.prenomBenef = child.prenom;
    benificiare.dateNaissance = child.dateNaissance;
    benificiare.idRegime = child.idRegime;
    benificiare.numSS = child.noSS;
    benificiare.cleSS = child.cleSec;
    benificiare.typeBenef = 3;
    benificiare.sexe = child.sexe;
    benificiare.codePostaleNaissance = child.codePostal;
    benificiare.villeNaissance = child.ville;
    benificiare.paysNaissance = child.paysNaissance;
    return benificiare;
  }

  private createBenificiaireFromAdherent(userUI) {
    const benificiare = new Beneficiaire();
    benificiare.nomBenef = userUI.nom;
    benificiare.prenomBenef = userUI.prenom;
    benificiare.dateNaissance = userUI.dateNaissance;
    benificiare.numSS = userUI.noSS;
    benificiare.cleSS = userUI.cleSec;
    benificiare.typeBenef = 1;
    benificiare.sexe = userUI.civilite;
    benificiare.paysNaissance = userUI.lieuNaissance.pays;
    benificiare.villeNaissance = userUI.lieuNaissance.ville;
    benificiare.codePostaleNaissance = userUI.lieuNaissance.codePostal;
    return benificiare;
  }

  private updateSouscripteur(souscripteur) {
    if (this.isAssure) {
      souscripteur.nom = this.userUI.nom;
      souscripteur.prenom = this.userUI.prenom;
      souscripteur.sexe = this.userUI.civilite;
      souscripteur.contact.telMobile = this.userUI.telephone;
      souscripteur.adressePostale.adresseLigne1 = this.userUI.adresseLigne1;
      souscripteur.adressePostale.codePostale = this.userUI.postalCodes;
      souscripteur.adressePostale.ville = this.userUI.ville;
      souscripteur.adressePostale.complement = this.userUI.complement;
      souscripteur.adressePostale.lieuDit = this.userUI.lieuDit;
    }
    if (!this.testForBlackSpaces(souscripteur.adressePostale.adresseLigne1)) {
      souscripteur.adressePostale.adresseLigne1 = '';
    }
    if (!this.testForBlackSpaces(souscripteur.adressePostale.complement)) {
      souscripteur.adressePostale.complement = '';
    }
    if (!this.testForBlackSpaces(souscripteur.adressePostale.lieuDit)) {
      this.userUI.lieuDit = '';
    }
    this.souscripteurService.updateSouscripteur(souscripteur).subscribe(data => {
      this.spinner.hide();
      if (this.goMandat) {
        this.cookies.set('previous', 'mes informations', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_INFORMATIONS_SANTE);
        this.router.navigate([OavConstants.MANDAT_SIGNATURE_SANTE_URL]);
      } else {
        this.cookies.set('previous', 'mes informations', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_SAUVEGARDE_INFORMATIONS_SANTE);
        this.router.navigate([OavConstants.SAUVEGARDE_DEVIS_SANTE_URL]);
      }
    }, error => {
      this.spinner.hide();
    });
  }

  checkDateFormat(date) {
    this.dateTouched = true;
    const re = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    this.showErrorFormat = !(re.test(String(date).toLowerCase()));
  }

  focusOnDateNaissance(date) {
    this.focused = true;
  }

  controlDate(date) {
    const temp = new Date(date).getFullYear() >= 1523;
    return (new Date(date).toString() !== 'Invalid Date' && temp);
  }

  private validSouscripteurInfoForScroll(souscripteur) {
    if (!this.isAssure) {
      return (this.validField(souscripteur.nom) && this.validField(souscripteur.prenom) &&
        souscripteur.adressePostale.adresseLigne1 && this.validCodePostal(souscripteur.adressePostale.codePostale) &&
        souscripteur.adressePostale.ville && this.validPhoneNumber(souscripteur.contact.telMobile) &&
        this.validMobileNumber(souscripteur.contact.telMobile));
    }
    return true;
  }

  private validAdherentInfoForScroll(userUI) {
    return this.validField(userUI.prenom) && this.validOuiNonRadio(userUI.assureur.resilier) &&
      this.validField(userUI.nom) && userUI.adresseLigne1 &&
      userUI.ville && this.validCodePostal(userUI.postalCodes) && this.validPhoneNumber(this.userUI.telephone) &&
      (this.validMobileNumber(this.userUI.telephone) || !this.isAssure) &&
      userUI.lieuNaissance.pays && userUI.lieuNaissance.ville &&
      this.verifNumSecSociale(userUI.noSS, userUI.dateNaissance, userUI.civilite) &&
      this.validCodePostalNaissance(userUI.lieuNaissance.codePostal, userUI.lieuNaissance.pays)
      && this.validKeyRespC(userUI) && this.validCleSec(userUI.cleSec) && (this.userUI.email && this.validEmail(this.userUI.email)) &&
      ((this.isChangeAttVitale && !this.attVitaleSizeError && !this.attVitaletypeError)
        || !this.isChangeAttVitale);
  }

  private scrollToError() {
    if (!this.validSouscripteurInfoForScroll(this.souscripteur) && !this.isAssure) {
      this.scrollToSouscripteurBloc();
    } else {
      if (!this.validAdherentInfoForScroll(this.userUI)) {
        this.scrollToAssureBloc();
      } else {

        if (!this.validAssureur(this.userUI.assureur)) {
          this.scrollToAssureurBloc();
        } else {
          if (!this.validConjointInfo(this.conjointUI)) {
            this.scrrollToConjointBloc();
          } else {
            if (!this.validChildrenData()) {
              this.scrollToChildrenBloc();
            }
          }
        }
      }
    }
  }

  private scrrollToConjointBloc() {
    if (!this.validField(this.conjointUI.prenom)) {
      this.scrollToElement('firstnameCon');
    } else {
      if (!this.validField(this.conjointUI.nom)) {
        this.scrollToElement('lastnameConj');
      } else {
        if (!this.conjointUI.paysNaissance) {
          this.scrollToElement('paysnConj');
        } else {
          if (!this.validCodePostalNaissance(this.conjointUI.codePostal, this.conjointUI.paysNaissance)) {
            this.scrollToElement('cpnConj');
          } else {
            if (!this.conjointUI.ville || !this.testForBlackSpaces(this.conjointUI.ville)) {
              if (this.conjointUI.paysNaissance === 'FR') {
                this.scrollToElement('villeConj');
              } else {
                this.scrollToElement('villeConj');
              }
            } else {
              if (!this.verifNumSecSociale(this.conjointUI.noSS, this.conjointUI.dateNaissance.toString(), this.conjointUI.sexe)
                || !this.conjointUI.noSS) {
                this.scrollToElement('numSecConj');
              } else {
                if (!this.validCleSec(this.conjointUI.cleSec) || !this.validKeyRespC(this.conjointUI) || !this.conjointUI.cleSec) {
                  this.scrollToElement('cleSecConj');
                } else {
                  if (this.isChangeAttVitaleConj) {
                    if (this.attVitaleConjSizeError || this.attVitaleConjtypeError) {
                      this.scrollToElement('attestationVitaleConjBloc');
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  private scrollToChildrenBloc() {
    let i = 0;
    for (const enfantDet of this.enfantList) {
      if (!this.validField(enfantDet.prenom)) {
        this.scrollToElement('firstnameEnf' + i);
        break;
      } else {
        if (!this.validField(enfantDet.nom)) {
          this.scrollToElement('lastnameEnf' + i);
          break;
        } else {
          if (!enfantDet.paysNaissance) {
            this.scrollToElement('paysnEnf' + i);
            break;
          } else {
            if (!this.validCodePostalNaissance(enfantDet.codePostal, enfantDet.paysNaissance)) {
              this.scrollToElement('cpnEnf' + i);
              break;
            } else {
              if (!enfantDet.ville || !this.testForBlackSpaces(enfantDet.ville)) {
                if (enfantDet.paysNaissance === 'FR') {
                  this.scrollToElement('villeEnf' + i);
                  break;
                } else {
                  this.scrollToElement('villeEnf1' + i);
                  break;
                }
              } else {
                if (!this.validNumSecSociale(enfantDet.noSS) || !enfantDet.noSS) {
                  this.scrollToElement('numSecEnf' + i);
                  break;
                } else {
                  if (!this.validCleSec(enfantDet.cleSec) || !this.validKeyRespC(enfantDet) || !enfantDet.cleSec) {
                    this.scrollToElement('cleSecEnf' + i);
                    break;
                  } else {
                    if (enfantDet.attestationVitale.isChange) {
                      if (enfantDet.attestationVitale.sizeError || enfantDet.attestationVitale.typeError) {
                        this.scrollToElement('attestationVitaleEnfBloc' + i);
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      i++;
    }
  }

  private scrollToAssureurBloc() {
    if (!this.userUI.assureur.mutuelle || !this.testForBlackSpaces(this.userUI.assureur.mutuelle)) {
      this.scrollToElement('mutuelle');
    } else {
      if (!this.userUI.assureur.contrat || !this.testForBlackSpaces(this.userUI.assureur.contrat)) {
        this.scrollToElement('contrat');
      } else {
        if (!this.userUI.assureur.dateEcheance || !this.controlDate(this.userUI.assureur.dateEcheance)) {
          this.scrollToElement('echeance');
        } else {
          if (!this.userUI.assureur.adressePostale.adresseLigne1 ||
            !this.testForBlackSpaces(this.userUI.assureur.adressePostale.adresseLigne1)) {
            this.scrollToElement('adrResiliation');
          } else {
            if (!this.validCodePostal(this.userUI.assureur.adressePostale.codePostale)) {
              this.scrollToElement('cpnResiliation');
            } else {
              if (!this.userUI.assureur.adressePostale.ville) {
                this.scrollToElement('villeResiliation');
              }
            }
          }
        }
      }
    }
  }

  private scrollToSouscripteurBloc() {
    if (!this.validField(this.souscripteur.prenom)) {
      this.scrollToElement('firstnameSouscripteur');
    } else {
      if (!this.validField(this.souscripteur.nom)) {
        this.scrollToElement('lastnameSouscripteur');
      } else {
        if (!this.souscripteur.adressePostale.adresseLigne1 || !this.testForBlackSpaces(this.souscripteur.adressePostale.adresseLigne1)) {
          this.scrollToElement('adrSouscripteur');
        } else {
          if (!this.validCodePostal(this.souscripteur.adressePostale.codePostale)) {
            this.scrollToElement('cpSouscripteur');
          } else {
            if (!this.souscripteur.adressePostale.ville || !this.testForBlackSpaces(this.souscripteur.adressePostale.ville)) {
              this.scrollToElement('villeSouscripteur');
            } else {
              if (!this.validPhoneNumber(this.souscripteur.contact.telMobile) ||
                !this.validMobileNumber(this.souscripteur.contact.telMobile)) {
                this.scrollToElement('telSouscripteur');
              }
            }
          }
        }
      }
    }
  }

  private scrollToAssureBloc() {
    if (!this.validField(this.userUI.prenom)) {
      this.scrollToElement('lastnameAssure');
    } else {
      if (!this.validField(this.userUI.nom)) {
        this.scrollToElement('firstnameAssure');
      } else {
        if (!this.userUI.adresseLigne1 || !this.testForBlackSpaces(this.userUI.adresseLigne1)) {
          this.scrollToElement('zip');
        } else {
          if (!this.validCodePostal(this.userUI.postalCodes)) {
            this.scrollToElement('cpAssure');
          } else {
            if (!this.userUI.ville || !this.testForBlackSpaces(this.userUI.ville)) {
              this.scrollToElement('ville');
            } else {
              if (!this.validPhoneNumber(this.userUI.telephone) || (!this.validMobileNumber(this.userUI.telephone) && this.isAssure)) {
                this.scrollToElement('tel');
              } else {
                if (!this.userUI.email || !this.validEmail(this.userUI.email)) {
                  this.scrollToElement('emailAssure');
                } else {
                  if (!this.userUI.lieuNaissance.pays) {
                    this.scrollToElement('paysn');
                  } else {
                    if (!this.validCodePostalNaissance(this.userUI.postalCodes, this.userUI.lieuNaissance.pays)) {
                      this.scrollToElement('cpn');
                    } else {
                      if (!this.userUI.lieuNaissance.ville || !this.testForBlackSpaces(this.userUI.lieuNaissance.ville)) {
                        if (this.userUI.lieuNaissance.pays === 'FR') {
                          this.scrollToElement('villeNaissance');
                        } else {
                          this.scrollToElement('villeNaissanceInput');
                        }
                      } else {
                        if (!this.verifNumSecSociale(this.userUI.noSS, this.userUI.dateNaissance, this.userUI.civilite)
                          || !this.userUI.noSS) {
                          this.scrollToElement('numSec');
                        } else {
                          if (!this.validCleSec(this.userUI.cleSec) || !this.validKeyRespC(this.userUI) || !this.userUI.cleSec) {
                            this.scrollToElement('cleSec');
                          } else {
                            if (this.isChangeAttVitale) {
                              if (this.attVitaleSizeError || this.attVitaletypeError) {
                                this.scrollToElement('attestationVitaleBloc');
                              }
                            } else {
                              if (!this.validOuiNonRadio(this.userUI.assureur.resilier)) {
                                this.scrollToElement('resiliationBloc');
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  private scrollToElement(id) {
    document.getElementById(id).scrollIntoView(true);
  }

  testForBlackSpaces(value) {
    if (value) {
      return value.toString().replace(/\s/g, '');
    }
    return true;
  }

  initResiliation() {
    this.showAssureur = !this.tryToSubmitForm;
    this.userUI.assureur.resilier = false;
    this.userUI.assureur.mutuelle = null;
    this.userUI.assureur.contrat = null;
    this.userUI.assureur.dateEcheance = null;
    this.userUI.assureur.adressePostale.adresseLigne1 = null;
    this.userUI.assureur.adressePostale.codePostale = null;
    this.userUI.assureur.adressePostale.ville = null;
  }

  private getBeneficiairesByUser(devis) {
    this.espaceProspectService.getBeneficiaireByIdUser(devis.user.id).subscribe((data: Array<Beneficiaire>) => {
      for (const benef of data) {
        if (benef.typeBenef === 1) {
          this.updateAssure(benef);
        } else if (benef.typeBenef === 2 && this.showConjoint &&
          new Date(benef.dateNaissance).getTime() === new Date(this.conjointUI.dateNaissance).getTime()) {
          this.updateConjoint(benef);
        } else if (benef.typeBenef === 3) {
          if (this.enfantList.length > 0) {
            for (const enfantData of this.enfantList) {
              if (new Date(benef.dateNaissance).getTime() === new Date(enfantData.dateNaissance).getTime()) {
                this.updateChild(benef, enfantData);
                break;
              }
            }
          }
        }
      }
    }, error => {
      console.error('error when get beneficiares list');
    });
  }

  private updateAssure(benef) {
    this.userUI.lieuNaissance.codePostal = benef.codePostaleNaissance && !this.userUI.lieuNaissance.codePostal ?
      benef.codePostaleNaissance : this.userUI.lieuNaissance.codePostal;
    this.userUI.lieuNaissance.pays = benef.paysNaissance && !this.userUI.lieuNaissance.pays ?
      benef.paysNaissance : this.userUI.lieuNaissance.pays;
    if (benef.villeNaissance && benef.codePostaleNaissance && !this.userUI.lieuNaissance.ville) {
      this.getAssureVillesNaissance(this.userUI.lieuNaissance.codePostal,benef.villeDeNaissance);
    }
    this.userUI.noSS = benef.numSS && !this.userUI.noSS ? benef.numSS : this.userUI.noSS;
    this.userUI.cleSec = benef.cleSS && !this.userUI.cleSec ? benef.cleSS : this.userUI.cleSec;
  }

  private updateConjoint(benef) {
    this.conjointUI.codePostal = benef.codePostaleNaissance && !this.conjointUI.codePostal ?
      benef.codePostaleNaissance : this.conjointUI.codePostal;
    this.conjointUI.paysNaissance = benef.paysNaissance && !this.conjointUI.paysNaissance ?
      benef.paysNaissance : this.conjointUI.paysNaissance;
    this.conjointUI.ville = benef.villeNaissance && benef.codePostaleNaissance && !this.conjointUI.ville ? benef.villeNaissance :
      this.conjointUI.ville;
    this.conjointUI.noSS = benef.numSS && !this.conjointUI.noSS ? benef.numSS : this.conjointUI.noSS;
    this.conjointUI.cleSec = benef.cleSS && !this.conjointUI.cleSec ? benef.cleSS : this.conjointUI.cleSec;
    this.conjointUI.nom = benef.nomBenef && !this.conjointUI.nom ? benef.nomBenef : this.conjointUI.nom;
    this.conjointUI.sexe = !this.validOuiNonRadio(this.conjointUI.sexe) ? benef.sexe : this.conjointUI.sexe;
    this.conjointUI.prenom = benef.prenomBenef && !this.conjointUI.prenom ? benef.prenomBenef : this.conjointUI.prenom;
    this.conjointUI.nomJeuneFille = benef.nomJeuneFille && !this.conjointUI.nomJeuneFille ?
      benef.nomJeuneFille : this.conjointUI.nomJeuneFille;
  }

  private updateChild(benef, enfantData) {
    enfantData.codePostal = benef.codePostaleNaissance && !enfantData.codePostal ? benef.codePostaleNaissance : enfantData.codePostal;
    enfantData.paysNaissance = benef.paysNaissance && !enfantData.paysNaissance ? benef.paysNaissance : enfantData.paysNaissance;
    enfantData.ville = benef.villeNaissance && !enfantData.ville ? benef.villeNaissance : enfantData.ville;
    enfantData.noSS = benef.numSS && !enfantData.noSS ? benef.numSS : enfantData.noSS;
    enfantData.cleSec = benef.cleSS && !enfantData.cleSec ? benef.cleSS : enfantData.cleSec;
    enfantData.nom = benef.nomBenef && !enfantData.nom ? benef.nomBenef : enfantData.nom;
    enfantData.sexe = benef.sexe && !this.validOuiNonRadio(enfantData.sexe) ? benef.sexe : enfantData.sexe;
    enfantData.prenom = benef.prenomBenef && !enfantData.prenom ? benef.prenomBenef : enfantData.prenom;
  }

  intiDateEcheance(resiliation, devis) {
    this.showAssureur = !this.tryToSubmitForm;
    if (resiliation) {
      // this part of code is only For IE 9, IE 10 and IE 11
      if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || /MSIE 10/i.test(navigator.userAgent)) {
        const dateToUse = moment(devis.dateAdhesion);
        const toDay = moment(new Date());
        const dt1 = new Date(Date.UTC(dateToUse.year(), dateToUse.month(), dateToUse.date() - 1));
        const dt2 = new Date(Date.UTC(toDay.year(), toDay.month(), toDay.date() + 33));
        this.userUI.assureur.dateEcheance = dt1 > dt2 ? dt1 : dt2;
      } else {
        const dt1 = new Date(new Date(devis.dateAdhesion).getFullYear(),
          new Date(devis.dateAdhesion).getMonth(), new Date(devis.dateAdhesion).getDate() - 1);
        const dt2 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 33);
        this.userUI.assureur.dateEcheance = dt1 > dt2 ? dt1 : dt2;
      }
    }
  }



  private filterUsers(filterRequestList: FilterRequestList) {
    this.filterService.filterUsers(filterRequestList).subscribe((result: FilterResponse) => {
      if (result === null) {
        this.filterErrorProcess();
      } else {
        let statusDevis = DevisStatus.MANDAT;
        if (result.assetFreezing === 'TRUE') {
          this.goMandat = false;
          this.cookies.set('filter', 'TRUE', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          statusDevis = DevisStatus.REJCTED;
        } else if (result.assetFreezing === 'FALSE') {
          this.cookies.set('filter', 'FALSE', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          this.goMandat = true;
        } else if (result.assetFreezing === 'PENDING') {
          this.cookies.set('filter', 'PENDING', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
          this.goMandat = false;
          statusDevis = DevisStatus.PENDING;
        } else {
          this.goMandat = true;
        }
        if (this.verifFormData()) {
          this.updateBenificiaire(this.enfantList, this.userUI, this.conjointUI, statusDevis);
          this.uploadFiles(this.enfantList, this.conjointUI);
        } else {
          this.scrollToError();
        }
      }
    }, error => {
      this.spinner.hide();
      console.error('error when filter users');
      this.filterErrorProcess();
    });
  }

  private filterErrorProcess() {
    this.cookies.set(OavConstants.FILTER_ERROR, OavConstants.RANDOM_STRING);
    this.router.navigate([OavConstants.SAUVEGARDE_DEVIS_SANTE_URL]);
  }


  private lockUserAccount() {
    this.spinner.show();
    this.devisService.getDevisById(this.cookies.get('devis')).subscribe((devis: DevisModelDTO) => {
      this.userService.lockAccount(devis.user.id).subscribe(data => {
        this.spinner.hide();
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_SAUVEGARDE_INFORMATIONS_SANTE
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.SAUVEGARDE_DEVIS_SANTE_URL]);
      }, error => {
        console.error('error when locking user account');
        this.spinner.hide();
      });
    }, error => {
      console.error('error when get devis by id');
      this.spinner.hide();
    });
  }

  trackByFn(index, item) {
    return index;
  }

  validMobileNumber(telMobile) {
    return telMobile && (this.showAssureTelMsg || this.showSouscripteurTelMsg || this.cookies.get('agent') ||
      (telMobile.toString().startsWith('06') || telMobile.toString().startsWith('07')));
  }

  checkForShowSouscripteurMsg() {
    if (this.cookies.get('agent') && !this.isAssure) {
      this.showAssureTelMsg = false;
      this.showSouscripteurTelMsg = true;
    }
  }

  checkForShowAssureMsg() {
    if (this.cookies.get('agent') && this.isAssure) {
      this.showAssureTelMsg = true;
      this.showSouscripteurTelMsg = false;
    }
  }

  openGeolocalisation() {
    this.santiclaireService.getGeoclaireUrl().subscribe((data: any) => {
      window.open(data.location, '_blank');
    }, error => {
      console.error('error when get santiclaire');
    });
  }
}
