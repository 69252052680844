import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { NgxSpinnerService } from 'ngx-spinner';
import { DevisService } from 'src/app/features/process-sante/services/devis.service';
import { DevisModelDTO } from 'src/app/features/process-sante/models/devisDTO';
import { FormulesService } from 'src/app/features/process-sante/services/formules.service';
import { CookieService } from 'ngx-cookie-service';
import { BdocService } from 'src/app/features/process-sante/services/bdoc.service';
import { BdocDTO } from 'src/app/features/process-sante/models/bdocDTO';
import { DocumentStatiqueModel } from 'src/app/shared/models/documentStatiqueModel';
import { DocumentStatiqueService } from 'src/app/shared/services/document-statique.service';
import { GuardService } from 'src/app/shared/services/guard.service';
import { AppConfig } from 'src/app/app.config';
import { SanticlaireService } from 'src/app/shared/services/santiclaire.service';


@Component({
  selector: 'app-formules-acds',
  templateUrl: './resumeFormule.component.html'

})

export class ResumeFormuleAcdsComponent implements OnInit {

  @Input() inputIdDevis: string;
  @Input() expired = false;
  @Input() abandonned = false;
  @Output() emitClose = new EventEmitter();
  @Input() isAgent = false;

  items = [{
    label: 'Mon Profil'
  },
  {
    label: 'Mes besoins'
  },
  {
    label: 'Formules'
  },
  {
    label: 'Votre devis'
  }
  ];
  activeIndex = 3;
  toggleCodeValue;
  devis: any;
  devisId: any;
  nbBenefs: any;
  refDevis;
  dateFinDevis;
  formuleLabel;
  prenom;
  nom;
  valDevis;
  bdocDto = new BdocDTO();
  data: any;
  email;
  showDocs;
  docsOpen = false;
  listDocumentStatique: DocumentStatiqueModel[] = [];
  parcours = this.cookies.get(OavConstants.PARCOURS);
  agentCRC = false;
  constructor(private cookies: CookieService, private router: Router, private devisService: DevisService,
    private formulesService: FormulesService, private bdocService: BdocService, private spinner: NgxSpinnerService,
    private documentStatiqueService: DocumentStatiqueService, private guardService: GuardService,
    private santiclaireService: SanticlaireService) { }

  ngOnInit() {
    if (this.cookies.get('agent')) {
      const agent = JSON.parse(this.cookies.get('agent'));
      this.agentCRC = agent.profil === '53';
    }
    this.checkForCurrentScreen();
    window.scroll(0, 0);
    this.spinner.show();
    if (this.inputIdDevis) {
      this.devisId = this.inputIdDevis;
    } else {
      this.devisId = this.cookies.get('devis');
    }
    this.initData();
    this.getListDocumentStatique();
    this.spinner.hide();
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => {});
  }

  getListDocumentStatique() {
    this.documentStatiqueService.getListDocumentsByProduitAndParcoursAndEcran('ACDS', this.getParcours(), 'Mes formules').
      subscribe(rep => {
        this.listDocumentStatique = rep;
        if (this.listDocumentStatique.length > 0) {
          this.showDocs = true;
        }
      });
  }

  getParcours() {
    if (this.parcours === 'MIXTE') {
      return 'Prospect';
    } else if (this.parcours === 'AGENT') {
      return 'Agent';
    } else {
      return null;
    }
  }

  initData() {
    this.devisService.getDevisById(this.devisId).subscribe((data: DevisModelDTO) => {
      this.email = data.souscripteur.contact.email;
      if (data.beneficiaires.length === 1 || (data.beneficiaires.length === 2 && data.beneficiaires[1].typeBenef === 4)) {
        this.nbBenefs = '1 Adulte';
      } else {
        this.nbBenefs = '1 Adulte et ' + (data.beneficiaires.length - 1) + ' bénéficiaires.';
      }
      this.devis = data;
      this.refDevis = this.devis.refDevis;
      this.dateFinDevis = this.devis.dateFinDevis;
      this.formuleLabel = this.devis.formule.formuleLabel;
      this.prenom = this.devis.beneficiaires[0].prenomBenef;
      this.nom = this.devis.beneficiaires[0].nomBenef;
      this.valDevis = this.devis.valDevis;
    }, error => {
      this.spinner.hide();
    });
  }

  continueAdhesion() {
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_RESUME_FORMULE_ACDS
      , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.cookies.set(OavConstants.QMS, '1');
    if (this.inputIdDevis) {
      this.cookies.set(OavConstants.DEVIS, this.inputIdDevis, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    }
    this.router.navigate([OavConstants.INFORMATION_ACDS_URL]);
  }

  backProfil() {
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    this.cookies.delete(OavConstants.DEVIS);
    if (!this.cookies.get('agent') && !this.cookies.get('client')) {
      this.cookies.delete('accessToken');
    }
    if (this.cookies.get('agent') && this.cookies.get('client')) {
      this.cookies.delete('client');
    }
    this.router.navigate([OavConstants.PROFIL_ACDS_URL]);
  }
  downloadPdf(type) {
    this.devis.formule.fileToDownload = type;
    this.formulesService.getPdf(this.devis).subscribe((data: ArrayBuffer) => {
      const newBlob = new Blob([data], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const dataa = window.URL.createObjectURL(newBlob);
      if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = dataa;
        a.download = type;
        a.click();
        return;
      }
      window.open(dataa);
    }, error => {
      this.spinner.hide();
    });
  }

  imprimerDevis() {
    this.spinner.show();
    this.devisId = this.cookies.get('devis');
    this.bdocDto.devisId = this.devisId;
    this.bdocService.getFileFromBdoc(this.bdocDto).subscribe(res => {
      this.spinner.hide();
      this.data = res;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        this.handeIE11DownloadFile(this.data.bdocReponse.documentb64, 'Devis.pdf');
      } else {
        const a = document.createElement('a');
        a.href = 'data:application/pdf;base64,' + this.data.bdocReponse.documentb64;
        a.download = 'Devis.pdf';
        a.click();
      }
    }, error => {
    });
  }

  close() {
    this.emitClose.emit();
  }

  handeIE11DownloadFile(data64, name) {
    window.navigator.msSaveBlob(this.base64ToBlob(data64), name);
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: 'application/pdf' });
  }

  openDocumentStatique(doc) {
    this.documentStatiqueService.getDocumentStatique(doc).subscribe(rep => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(this.base64ToBlob(rep.encoded));
      } else {
        const fileURL = URL.createObjectURL(this.base64ToBlob(rep.encoded));
        if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = doc;
          a.click();
          return;
        }
        window.open(fileURL);
      }
    });
  }

  openGeolocalisation() {
    this.santiclaireService.getGeoclaireUrl().subscribe((data: any) => {
      window.open(data.location, '_blank');
    }, error => {
      console.error('error when get santiclaire');
    });
  }

}
