import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { DevisService } from 'src/app/features/process-sante/services/devis.service';
import { DevisModelDTO } from '../../models/devisDTO';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { BdocService } from '../../services/bdoc.service';
import { BdocDTO } from '../../models/bdocDTO';
import { CookieService } from 'ngx-cookie-service';
import { DevisStatus } from '../../models/devisStatus';
import { SignatureService } from '../../services/signature.service';
import { TessiSignDocsModelDTO } from '../../models/tessiSignDocsDTO';
import { GuardService } from 'src/app/shared/services/guard.service';
import { AppConfig } from 'src/app/app.config';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { FormulesService } from '../../services/formules.service';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-validation-sante',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.css']
})
export class ValidationSanteComponent implements OnInit {
  showCards = false;
  adherentDto = {};
  devisId;
  montantTotal = 20;
  formuleLabel;
  emailAdherent;
  toggleValid;

  items = [
    {
      label: 'Mes informations'
    },
    {
      label: 'Mandat'
    },
    {
      label: 'Signature'
    },
    {
      label: 'Votre contrat'
    }
  ];
  activeIndex = 3;
  devis: any;
  refContrat;
  dateFinDevis;
  prenom;
  nom;
  valDevis;
  nbBenefs: any;
  produit;
  type;
  signType = false;
  bdocDto = new BdocDTO();
  data: any;
  status: any;
  @Input() inputIdDevis: string;
  @Output() emitClose = new EventEmitter();
  @Input() isAgent = false;
  docNames;
  errorPDF;
  showPayed = false;
  transactionDate;
  payedMount;
  dataLoaded = false;
  constructor(private spinner: NgxSpinnerService, private devisService: DevisService,
    private router: Router, private cookies: CookieService, private guardService: GuardService,
    private bdocService: BdocService, private signatureService: SignatureService, private paymentService: PaymentService,private formuleService: FormulesService ,private location:LocationStrategy) { 
      history.pushState(null, null, window.location.href);
      this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });
        }
  ngOnInit() {
    this.spinner.show();
    this.devisId = this.inputIdDevis ? this.inputIdDevis : this.cookies.get(OavConstants.DEVIS);
    this.initData();
    this.checkForCurrentScreen();
  }


  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => { });
  }

  initData() {
    this.devisService.getDevisById(this.devisId).subscribe((data: DevisModelDTO) => {
      this.devis = data;
      let nbAdult = 0;
      let nbChilds = 0;
      let strAdulte;
      let strChilds;
      if (data.beneficiaires.length === 1) {
        this.nbBenefs = '1 Adulte';
      } else {
        data.beneficiaires.forEach(element => {
          if (element.typeBenef === 1) {
            nbAdult = 1;
          } else if (element.typeBenef === 2) {
            nbAdult = nbAdult + 1;
          } else if (element.typeBenef === 3) {
            nbChilds = nbChilds + 1;
          }
        });
        if (nbAdult > 1) {
          strAdulte = ' Adultes';
        } else {
          strAdulte = ' Adulte';
        }
        if (nbChilds === 1) {
          strChilds = ' et ' + nbChilds + ' enfant.';
        } else if (nbChilds > 1) {
          strChilds = ' et ' + nbChilds + ' enfants.';
        } else {
          strChilds = '.';
        }
        this.nbBenefs = nbAdult + strAdulte + strChilds;
      }
      this.devis = data;
      this.refContrat = this.devis.refContrat;
      this.dateFinDevis = this.devis.dateFinDevis;
      this.formuleLabel = this.devis.formule.formuleLabel;
      this.prenom = this.devis.beneficiaires[0].prenomBenef;
      this.nom = this.devis.beneficiaires[0].nomBenef;
      this.valDevis = this.devis.valDevis;
      this.type = 'Assurance Santé';
      this.updateDevisStatus(DevisStatus.A_ENVOYER_EN_GESTION);
    }, error => {
      this.spinner.hide();
    });
  }

  downloadPdf(pdf) {
    this.signatureService.getPdf(pdf.id).subscribe((data: ArrayBuffer) => {
      const newBlob = new Blob([data], { type: 'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob, pdf.documentType + '.pdf');
        return;
      }
      const dataa = window.URL.createObjectURL(newBlob);
      if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = dataa;
        a.download = pdf.documentType;
        a.click();
        return;
      }
      window.open(dataa);
    }, error => {
      this.spinner.hide();
      this.errorPDF = true;
    });
  }

  close() {
    this.cookies.delete('devis');
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    if (this.cookies.get('agent')) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_AGENT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.ESPACE_AGENT]);
    } else {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.ESPACE_PROSPECT]);
    }
  }

  updateDevisStatus(status) {
    this.devisService.updateDevisStatus(this.devisId, status).subscribe((data: DevisModelDTO) => {
      this.getLatestByDevis();
    }, error => {
      console.error('error when update devis status');
      this.spinner.hide();
    });
  }

  private getLatestByDevis() {
    this.paymentService.getLatestByDevis(this.devisId).subscribe((data: any) => {
      if (data && data.status === 'PAID') {
        this.showPayed = true;
        this.transactionDate = data.legacyTransDate;
        this.payedMount = data.effectiveAmount / 100;
      }
      this.initDocs();
    }, error => {
      this.spinner.hide();
      console.error('error when get latest payment by devis');
    });
  }

  imprimerDevis() {
    this.spinner.show();
    this.bdocDto.devisId = this.devisId;
    this.bdocService.getFileFromBdoc(this.bdocDto).subscribe(res => {
      this.spinner.hide();
      this.data = res;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        this.handeIE11DownloadFile(this.data.bdocReponse.documentb64, 'Contrat.pdf');
      } else {
        const a = document.createElement('a');
        a.href = 'data:application/pdf;base64,' + this.data.bdocReponse.documentb64;
        a.download = 'Contrat.pdf';
        a.click();
      }
    }, error => {
      console.error('error when download contrat');
      this.spinner.hide();
    });
  }

  handeIE11DownloadFile(data64, name) {
    window.navigator.msSaveBlob(this.base64ToBlob(data64), name);
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: 'application/pdf' });
  }


  initDocs() {
    let parcours = 'MIXTE';
    if (this.cookies.get('agent')) {
      parcours = 'AGENT';
    }
    this.signatureService.getDocs(this.devisId, parcours).subscribe((res: Array<TessiSignDocsModelDTO>) => {
      this.docNames = res;
      this.dataLoaded = true;
      this.spinner.hide();
    }, error => {
      this.errorPDF = true;
    });
  }

  trackByFn(index, item) {
    return index;
  }
  downloadCG() {
    this.downloadPdfFile('CG');
  }
  downloadProduitAssuranceDoc() {
    this.downloadPdfFile('IPID');
  }

  downloadPdfFile(type) {
    this.devis.formule.fileToDownload = type;
    this.formuleService.getPdf(this.devis).subscribe((data: ArrayBuffer) => {
      const newBlob = new Blob([data], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const dataa = window.URL.createObjectURL(newBlob);
      if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = dataa;
        a.download = type;
        a.click();
        return;
      }
      window.open(dataa);
    }, error => {
      this.spinner.hide();
    });
  }
}
