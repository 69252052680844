import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { NgxSpinnerService } from 'ngx-spinner';
import { DevisService } from '../../services/devis.service';
import { DevisModelDTO } from '../../models/devisDTO';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-sauvegarde-devis',
  templateUrl: './sauvegarde-devis.component.html',
  styleUrls: ['./sauvegarde-devis.component.css']
})
export class SauvegardeDevisComponent implements OnInit {

  devisId;
  devis;
  refDevis;
  filter;
  agent;
  filterError = false;
  constructor(private router: Router, private spinner: NgxSpinnerService, private devisService: DevisService,
    private cookies: CookieService) { }

  ngOnInit() {
    this.filter = this.cookies.get('filter');
    this.agent = this.cookies.get('agent');
    window.scroll(0, 0);
    this.devisId =  this.cookies.get('devis');
    this.filterError = this.cookies.check(OavConstants.FILTER_ERROR);
    this.initData();
  }

  redirectToLogin() {
    this.cookies.delete(OavConstants.CURRENT_SCREEN);
    this.cookies.delete(OavConstants.DEVIS);
    this.cookies.delete(OavConstants.FILTER_ERROR);
    if (this.cookies.get(OavConstants.IS_CONNECTED) === OavConstants.TRUE) {
      if (this.cookies.get('user') === 'false') {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_AGENT
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.ESPACE_AGENT]);
      } else {
        this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ESPACE_PROSPECT
          , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
        this.router.navigate([OavConstants.ESPACE_PROSPECT]);
      }
    } else {
      this.router.navigate([OavConstants.LOGIN_URL]);
    }
  }

  initData() {
    this.devisService.getDevisById(this.devisId).subscribe((data: DevisModelDTO) => {
      this.devis = data;
      this.devis = data;
      this.refDevis = this.devis.refDevis;
    }, error => {
      this.spinner.hide();
    });
  }
}
