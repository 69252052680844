import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-renit-mail-info',
  templateUrl: './renit-mail-info.component.html',
  styleUrls: ['./renit-mail-info.component.css']
})
export class RenitMailInfoComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  authenticate() {
    this.router.navigate(['/login']);
  }
}
