import { Injectable } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FilesDTO } from '../models/filesDTO';
import { CookieService } from 'ngx-cookie-service';
import { OavUrlConstants } from '../../../shared/data/OavURLConstants';

@Injectable({
  providedIn: 'root'
})
export class BeneficiareService {
  privateUri = AppConfig.securedEndpoint + OavUrlConstants.BENEFICIAIRE_ENDPOINT;

  constructor(private http: HttpClient, private cookie: CookieService) { }


  getEtablissements() {
    return this.http.get(this.privateUri + OavUrlConstants.GET_ETABLISSEMENTS);
  }

  getPays() {
    return this.http.get(this.privateUri + OavUrlConstants.GET_PAYS);
  }

  getNationalities() {
    return this.http.get(this.privateUri + OavUrlConstants.GET_NATIONALITIES);
  }

  uploadFiles(filesDTO: FilesDTO) {
    const formdata = new FormData();
    let i = 0;
    const beneficiaires = new Array<number>();
    for (const f of filesDTO.files) {
      if (f.attestation) {
        formdata.append('attestations', f.attestation);
        beneficiaires.push(f.idBenef);
        i++;
      }
    }
    formdata.append('beneficiares', JSON.stringify(beneficiaires));
    formdata.append('adherentDTO', filesDTO.idAdherent.toString());
    formdata.append('idDevis', filesDTO.idDevis.toString());
    const headers = new HttpHeaders({});
    headers.set('Content-Type', 'multipart/form-data; boundary=${formdata._boundary};charset=UTF-8');
    const options = { headers: headers, reportProgress: true };
    return this.http.post(this.privateUri + OavUrlConstants.UPLOAD_DOCS, formdata, options);
  }

  updateBenificiare(data) {
    return this.http.post(this.privateUri + OavUrlConstants.UPDATE_INFO, data);
  }

  getDevisDocument(beneficiare, devis) {
    return this.http.get(this.privateUri + OavUrlConstants.GET_DEVIS_DOCUMENT + '/' + beneficiare + '/' + devis);
  }

  deleteAllEnfantsOfDevis(idDevis) {
    return this.http.delete(this.privateUri + OavUrlConstants.DELETE_BY_DEVIS + '?idDevis=' + idDevis);
  }
}
