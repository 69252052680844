import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from 'src/app/app.config';
import { CookiesEntriesModel } from 'src/app/shared/models/cookiesEntries.Model';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {

  constructor(private cookies: CookieService, private router: Router) { }

  ngOnInit() {
  }

  removeGoogleAnalytics() {
    const internalCookiesEntries = new CookiesEntriesModel().entries;
    this.cookies.set('accept-cookies', 'false', 30 * 13, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    Object.keys(this.cookies.getAll()).forEach(cookie => {
      if (!(internalCookiesEntries.includes(cookie)) && cookie !== 'accept-cookies') {
        this.cookies.delete(cookie);
      }
    });
    location.reload();
  }
}
